import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19a90a76 = () => interopDefault(import('../pages/accountSettings.vue' /* webpackChunkName: "pages/accountSettings" */))
const _b015c0d6 = () => interopDefault(import('../pages/announcements/index.vue' /* webpackChunkName: "pages/announcements/index" */))
const _020b74ac = () => interopDefault(import('../pages/app_order_detail.vue' /* webpackChunkName: "pages/app_order_detail" */))
const _3fce8062 = () => interopDefault(import('../pages/appAccountVerify.vue' /* webpackChunkName: "pages/appAccountVerify" */))
const _3fc1f275 = () => interopDefault(import('../pages/appEmailChange.vue' /* webpackChunkName: "pages/appEmailChange" */))
const _4aee6986 = () => interopDefault(import('../pages/appPasswordReset.vue' /* webpackChunkName: "pages/appPasswordReset" */))
const _4f816aa0 = () => interopDefault(import('../pages/auditLogs.vue' /* webpackChunkName: "pages/auditLogs" */))
const _3590945c = () => interopDefault(import('../pages/bank_accounts/index.vue' /* webpackChunkName: "pages/bank_accounts/index" */))
const _784631e0 = () => interopDefault(import('../pages/banners/index.vue' /* webpackChunkName: "pages/banners/index" */))
const _e96d0830 = () => interopDefault(import('../pages/batch_push_notification_reservations/index.vue' /* webpackChunkName: "pages/batch_push_notification_reservations/index" */))
const _0497ddef = () => interopDefault(import('../pages/bug-reports/index.vue' /* webpackChunkName: "pages/bug-reports/index" */))
const _5cde4867 = () => interopDefault(import('../pages/bundle_rules/index.vue' /* webpackChunkName: "pages/bundle_rules/index" */))
const _46fd91e7 = () => interopDefault(import('../pages/catalogs/index.vue' /* webpackChunkName: "pages/catalogs/index" */))
const _04676ea2 = () => interopDefault(import('../pages/compensations/index.vue' /* webpackChunkName: "pages/compensations/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _54d7e454 = () => interopDefault(import('../pages/coupons/index.vue' /* webpackChunkName: "pages/coupons/index" */))
const _794effcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _cada89c8 = () => interopDefault(import('../pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _2c8f4028 = () => interopDefault(import('../pages/delivery_area_groups/index.vue' /* webpackChunkName: "pages/delivery_area_groups/index" */))
const _7edb26f9 = () => interopDefault(import('../pages/delivery_area_migrations/index.vue' /* webpackChunkName: "pages/delivery_area_migrations/index" */))
const _26df7528 = () => interopDefault(import('../pages/delivery_areas/index.vue' /* webpackChunkName: "pages/delivery_areas/index" */))
const _ae13a2da = () => interopDefault(import('../pages/delivery_cars/index.vue' /* webpackChunkName: "pages/delivery_cars/index" */))
const _07fbdadc = () => interopDefault(import('../pages/delivery_time_setting/index.vue' /* webpackChunkName: "pages/delivery_time_setting/index" */))
const _743b0ae8 = () => interopDefault(import('../pages/delivery_times/index.vue' /* webpackChunkName: "pages/delivery_times/index" */))
const _2c55fef9 = () => interopDefault(import('../pages/delivery_vouchers/index.vue' /* webpackChunkName: "pages/delivery_vouchers/index" */))
const _e9bed37e = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _f537fc90 = () => interopDefault(import('../pages/download_procurement_masters/index.vue' /* webpackChunkName: "pages/download_procurement_masters/index" */))
const _c7b565c0 = () => interopDefault(import('../pages/ec_site_categories/index.vue' /* webpackChunkName: "pages/ec_site_categories/index" */))
const _01d9e7fe = () => interopDefault(import('../pages/ec_site_pickup_shops/index.vue' /* webpackChunkName: "pages/ec_site_pickup_shops/index" */))
const _4dc5bd91 = () => interopDefault(import('../pages/external_admin_users/index.vue' /* webpackChunkName: "pages/external_admin_users/index" */))
const _71c1faea = () => interopDefault(import('../pages/features/index.vue' /* webpackChunkName: "pages/features/index" */))
const _23646f5e = () => interopDefault(import('../pages/finalize_sale/index.vue' /* webpackChunkName: "pages/finalize_sale/index" */))
const _c4c89e74 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _7c9d4642 = () => interopDefault(import('../pages/loading.vue' /* webpackChunkName: "pages/loading" */))
const _081a90d9 = () => interopDefault(import('../pages/master_datas/index.vue' /* webpackChunkName: "pages/master_datas/index" */))
const _7824023e = () => interopDefault(import('../pages/newPassword.vue' /* webpackChunkName: "pages/newPassword" */))
const _358c1412 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _de8fe360 = () => interopDefault(import('../pages/outgoingCallHistory.vue' /* webpackChunkName: "pages/outgoingCallHistory" */))
const _107068c0 = () => interopDefault(import('../pages/payment_issues/index.vue' /* webpackChunkName: "pages/payment_issues/index" */))
const _0af18e2e = () => interopDefault(import('../pages/picking_list/index.vue' /* webpackChunkName: "pages/picking_list/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _6e5c10fc = () => interopDefault(import('../pages/recoverEmail.vue' /* webpackChunkName: "pages/recoverEmail" */))
const _40bbafa4 = () => interopDefault(import('../pages/reservation_products/index.vue' /* webpackChunkName: "pages/reservation_products/index" */))
const _3470a452 = () => interopDefault(import('../pages/sendPasswordReset.vue' /* webpackChunkName: "pages/sendPasswordReset" */))
const _1e0b1906 = () => interopDefault(import('../pages/shop_groups/index.vue' /* webpackChunkName: "pages/shop_groups/index" */))
const _044611a8 = () => interopDefault(import('../pages/signIn.vue' /* webpackChunkName: "pages/signIn" */))
const _12734da2 = () => interopDefault(import('../pages/signUp.vue' /* webpackChunkName: "pages/signUp" */))
const _35a136e2 = () => interopDefault(import('../pages/stailer_standard_finalize_sale/index.vue' /* webpackChunkName: "pages/stailer_standard_finalize_sale/index" */))
const _324e9850 = () => interopDefault(import('../pages/stock_arrivals/index.vue' /* webpackChunkName: "pages/stock_arrivals/index" */))
const _9d784d5a = () => interopDefault(import('../pages/subscription_users/index.vue' /* webpackChunkName: "pages/subscription_users/index" */))
const _c0287620 = () => interopDefault(import('../pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _9cd166ac = () => interopDefault(import('../pages/transaction_records/index.vue' /* webpackChunkName: "pages/transaction_records/index" */))
const _3128aa62 = () => interopDefault(import('../pages/upload_product_images/index.vue' /* webpackChunkName: "pages/upload_product_images/index" */))
const _c91b56de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _267fccb9 = () => interopDefault(import('../pages/account_transfer/fail/index.vue' /* webpackChunkName: "pages/account_transfer/fail/index" */))
const _e2d98442 = () => interopDefault(import('../pages/account_transfer/requests/index.vue' /* webpackChunkName: "pages/account_transfer/requests/index" */))
const _7e5c68de = () => interopDefault(import('../pages/account_transfer/results/index.vue' /* webpackChunkName: "pages/account_transfer/results/index" */))
const _1fb6faff = () => interopDefault(import('../pages/account_transfer/statements/index.vue' /* webpackChunkName: "pages/account_transfer/statements/index" */))
const _e8944fba = () => interopDefault(import('../pages/announcements/new.vue' /* webpackChunkName: "pages/announcements/new" */))
const _21e9a594 = () => interopDefault(import('../pages/batch_push_notification_reservations/new.vue' /* webpackChunkName: "pages/batch_push_notification_reservations/new" */))
const _1d74fc68 = () => interopDefault(import('../pages/bug-reports/completed.vue' /* webpackChunkName: "pages/bug-reports/completed" */))
const _487ab6f5 = () => interopDefault(import('../pages/catalogs/new.vue' /* webpackChunkName: "pages/catalogs/new" */))
const _0e9a8670 = () => interopDefault(import('../pages/compensations/new.vue' /* webpackChunkName: "pages/compensations/new" */))
const _9de53ab8 = () => interopDefault(import('../pages/coupons/new.vue' /* webpackChunkName: "pages/coupons/new" */))
const _61b373ad = () => interopDefault(import('../pages/debug/bank_account_transfer/index.vue' /* webpackChunkName: "pages/debug/bank_account_transfer/index" */))
const _57d1042d = () => interopDefault(import('../pages/debug/copy_order/index.vue' /* webpackChunkName: "pages/debug/copy_order/index" */))
const _10a82c08 = () => interopDefault(import('../pages/debug/finsih_pick_pack/index.vue' /* webpackChunkName: "pages/debug/finsih_pick_pack/index" */))
const _afd68060 = () => interopDefault(import('../pages/delivery_area_groups/download_modal.vue' /* webpackChunkName: "pages/delivery_area_groups/download_modal" */))
const _f3367996 = () => interopDefault(import('../pages/delivery_area_groups/import_histories/index.vue' /* webpackChunkName: "pages/delivery_area_groups/import_histories/index" */))
const _18665a89 = () => interopDefault(import('../pages/delivery_area_groups/upload_modal.vue' /* webpackChunkName: "pages/delivery_area_groups/upload_modal" */))
const _f783fa36 = () => interopDefault(import('../pages/delivery_area_migrations/create.vue' /* webpackChunkName: "pages/delivery_area_migrations/create" */))
const _7d801b64 = () => interopDefault(import('../pages/delivery_times/edit.vue' /* webpackChunkName: "pages/delivery_times/edit" */))
const _2363728e = () => interopDefault(import('../pages/delivery_vouchers/print_collecting_containers.vue' /* webpackChunkName: "pages/delivery_vouchers/print_collecting_containers" */))
const _6d6cde3a = () => interopDefault(import('../pages/delivery_vouchers/print_delivery_catalogs.vue' /* webpackChunkName: "pages/delivery_vouchers/print_delivery_catalogs" */))
const _5c107608 = () => interopDefault(import('../pages/delivery_vouchers/print_delivery_route_instruction.vue' /* webpackChunkName: "pages/delivery_vouchers/print_delivery_route_instruction" */))
const _3e90cf9a = () => interopDefault(import('../pages/delivery_vouchers/print_normal_delivery_orders.vue' /* webpackChunkName: "pages/delivery_vouchers/print_normal_delivery_orders" */))
const _65967ef6 = () => interopDefault(import('../pages/delivery_vouchers/print_receipts.vue' /* webpackChunkName: "pages/delivery_vouchers/print_receipts" */))
const _07e9b753 = () => interopDefault(import('../pages/delivery_vouchers/print_scheduled_delivery_orders.vue' /* webpackChunkName: "pages/delivery_vouchers/print_scheduled_delivery_orders" */))
const _39fb8cf0 = () => interopDefault(import('../pages/delivery_vouchers/print_scheduled_receipts.vue' /* webpackChunkName: "pages/delivery_vouchers/print_scheduled_receipts" */))
const _566709cf = () => interopDefault(import('../pages/documents/new.vue' /* webpackChunkName: "pages/documents/new" */))
const _a0506d14 = () => interopDefault(import('../pages/ec_site_shops/register.vue' /* webpackChunkName: "pages/ec_site_shops/register" */))
const _dab38166 = () => interopDefault(import('../pages/external_admin_users/create.vue' /* webpackChunkName: "pages/external_admin_users/create" */))
const _ef1a024c = () => interopDefault(import('../pages/external_admin_users/invite.vue' /* webpackChunkName: "pages/external_admin_users/invite" */))
const _7fa9845c = () => interopDefault(import('../pages/fulfillment/product_arrangement/index.vue' /* webpackChunkName: "pages/fulfillment/product_arrangement/index" */))
const _2e4eca67 = () => interopDefault(import('../pages/master_datas/new.vue' /* webpackChunkName: "pages/master_datas/new" */))
const _7a5b90ec = () => interopDefault(import('../pages/master_datas/upload.vue' /* webpackChunkName: "pages/master_datas/upload" */))
const _24896cf9 = () => interopDefault(import('../pages/orders/create_sheet.ts' /* webpackChunkName: "pages/orders/create_sheet" */))
const _1608b0b6 = () => interopDefault(import('../pages/orders/order_helper.ts' /* webpackChunkName: "pages/orders/order_helper" */))
const _0adb8f26 = () => interopDefault(import('../pages/orders/print.vue' /* webpackChunkName: "pages/orders/print" */))
const _482a4731 = () => interopDefault(import('../pages/orders/print_order_list.vue' /* webpackChunkName: "pages/orders/print_order_list" */))
const _12a89252 = () => interopDefault(import('../pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _80e54578 = () => interopDefault(import('../pages/picking_list/print.vue' /* webpackChunkName: "pages/picking_list/print" */))
const _646611db = () => interopDefault(import('../pages/products/edit.vue' /* webpackChunkName: "pages/products/edit" */))
const _c7631e08 = () => interopDefault(import('../pages/recurring_order_settings/users/index.vue' /* webpackChunkName: "pages/recurring_order_settings/users/index" */))
const _60c035c2 = () => interopDefault(import('../pages/recurring_orders/payment_faileds/index.vue' /* webpackChunkName: "pages/recurring_orders/payment_faileds/index" */))
const _e787cf84 = () => interopDefault(import('../pages/suppliers/new.vue' /* webpackChunkName: "pages/suppliers/new" */))
const _a88de8d6 = () => interopDefault(import('../pages/debug/register_debug_delivery/create.vue' /* webpackChunkName: "pages/debug/register_debug_delivery/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _276c4e7b = () => interopDefault(import('../pages/public/announcements/_id.vue' /* webpackChunkName: "pages/public/announcements/_id" */))
const _3c10cf27 = () => interopDefault(import('../pages/public/documents/_id.vue' /* webpackChunkName: "pages/public/documents/_id" */))
const _ed770614 = () => interopDefault(import('../pages/public/terms/_id.vue' /* webpackChunkName: "pages/public/terms/_id" */))
const _4081165c = () => interopDefault(import('../pages/recurring_order_settings/users/_userId/index.vue' /* webpackChunkName: "pages/recurring_order_settings/users/_userId/index" */))
const _41f67eac = () => interopDefault(import('../pages/recurring_orders/payment_faileds/_id.vue' /* webpackChunkName: "pages/recurring_orders/payment_faileds/_id" */))
const _202b7728 = () => interopDefault(import('../pages/account_transfer/statements/_statementId/print.vue' /* webpackChunkName: "pages/account_transfer/statements/_statementId/print" */))
const _6831cc3a = () => interopDefault(import('../pages/recurring_order_settings/users/_userId/edit.vue' /* webpackChunkName: "pages/recurring_order_settings/users/_userId/edit" */))
const _54996d78 = () => interopDefault(import('../pages/banners/_id.vue' /* webpackChunkName: "pages/banners/_id" */))
const _34c4e20f = () => interopDefault(import('../pages/catalogs/_id.vue' /* webpackChunkName: "pages/catalogs/_id" */))
const _c0a89430 = () => interopDefault(import('../pages/contacts/_id.vue' /* webpackChunkName: "pages/contacts/_id" */))
const _03820692 = () => interopDefault(import('../pages/delivery_area_groups/_delivery_area_group_id.vue' /* webpackChunkName: "pages/delivery_area_groups/_delivery_area_group_id" */))
const _426ed8be = () => interopDefault(import('../pages/delivery_area_migrations/_id.vue' /* webpackChunkName: "pages/delivery_area_migrations/_id" */))
const _09568c8a = () => interopDefault(import('../pages/delivery_cars/_id.vue' /* webpackChunkName: "pages/delivery_cars/_id" */))
const _4890dd0c = () => interopDefault(import('../pages/delivery_time_setting/_id.vue' /* webpackChunkName: "pages/delivery_time_setting/_id" */))
const _4c85afd2 = () => interopDefault(import('../pages/features/_id.vue' /* webpackChunkName: "pages/features/_id" */))
const _716826ba = () => interopDefault(import('../pages/orders/_orderId.vue' /* webpackChunkName: "pages/orders/_orderId" */))
const _ac0d69e8 = () => interopDefault(import('../pages/reservation_products/_id.vue' /* webpackChunkName: "pages/reservation_products/_id" */))
const _6c8e147b = () => interopDefault(import('../pages/subscription_users/_id.vue' /* webpackChunkName: "pages/subscription_users/_id" */))
const _78864358 = () => interopDefault(import('../pages/suppliers/_id.vue' /* webpackChunkName: "pages/suppliers/_id" */))
const _9df71cdc = () => interopDefault(import('../pages/transaction_records/_id.vue' /* webpackChunkName: "pages/transaction_records/_id" */))
const _1c03718e = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _46f4bb4c = () => interopDefault(import('../pages/announcements/_id/edit.vue' /* webpackChunkName: "pages/announcements/_id/edit" */))
const _45cecbce = () => interopDefault(import('../pages/batch_push_notification_reservations/_id/edit.vue' /* webpackChunkName: "pages/batch_push_notification_reservations/_id/edit" */))
const _0ffa093a = () => interopDefault(import('../pages/bundle_rules/_id/edit.vue' /* webpackChunkName: "pages/bundle_rules/_id/edit" */))
const _38e4996b = () => interopDefault(import('../pages/coupons/_id/edit.vue' /* webpackChunkName: "pages/coupons/_id/edit" */))
const _45dbb520 = () => interopDefault(import('../pages/documents/_id/edit.vue' /* webpackChunkName: "pages/documents/_id/edit" */))
const _4daaae43 = () => interopDefault(import('../pages/ec_site_pickup_shops/_id/edit.vue' /* webpackChunkName: "pages/ec_site_pickup_shops/_id/edit" */))
const _07193e12 = () => interopDefault(import('../pages/ec_site_shops/_id/edit.vue' /* webpackChunkName: "pages/ec_site_shops/_id/edit" */))
const _0edca0d0 = () => interopDefault(import('../pages/master_datas/_name/edit.vue' /* webpackChunkName: "pages/master_datas/_name/edit" */))
const _3e143459 = () => interopDefault(import('../pages/master_datas/_name/files.vue' /* webpackChunkName: "pages/master_datas/_name/files" */))
const _84736260 = () => interopDefault(import('../pages/orders/_id/compensation_entries.vue' /* webpackChunkName: "pages/orders/_id/compensation_entries" */))
const _03e11226 = () => interopDefault(import('../pages/orders/_id/compensation_invoice.vue' /* webpackChunkName: "pages/orders/_id/compensation_invoice" */))
const _1a4dd125 = () => interopDefault(import('../pages/users/_userId/bank_account.vue' /* webpackChunkName: "pages/users/_userId/bank_account" */))
const _25b2cfa2 = () => interopDefault(import('../pages/users/_userId/bank_accounts/new.vue' /* webpackChunkName: "pages/users/_userId/bank_accounts/new" */))
const _9c54587e = () => interopDefault(import('../pages/users/_userId/bank_accounts/manual_transfers/new.vue' /* webpackChunkName: "pages/users/_userId/bank_accounts/manual_transfers/new" */))
const _7d73666f = () => interopDefault(import('../pages/subscription_users/_userId/plans/_id/index.vue' /* webpackChunkName: "pages/subscription_users/_userId/plans/_id/index" */))
const _74ddfcef = () => interopDefault(import('../pages/users/_userId/bank_account_transfer_summaries/_bankAccountTransferSummaryId.vue' /* webpackChunkName: "pages/users/_userId/bank_account_transfer_summaries/_bankAccountTransferSummaryId" */))
const _858c222c = () => interopDefault(import('../pages/catalogs/_catalogId/pages/_pageId/edit.vue' /* webpackChunkName: "pages/catalogs/_catalogId/pages/_pageId/edit" */))
const _90ca9a06 = () => interopDefault(import('../pages/subscription_users/_userId/plans/_id/edit.vue' /* webpackChunkName: "pages/subscription_users/_userId/plans/_id/edit" */))
const _2763b3ca = () => interopDefault(import('../pages/users/_userId/bank_accounts/_bankAccountId/edit.vue' /* webpackChunkName: "pages/users/_userId/bank_accounts/_bankAccountId/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accountSettings",
    component: _19a90a76,
    name: "accountSettings"
  }, {
    path: "/announcements",
    component: _b015c0d6,
    name: "announcements"
  }, {
    path: "/app_order_detail",
    component: _020b74ac,
    name: "app_order_detail"
  }, {
    path: "/appAccountVerify",
    component: _3fce8062,
    name: "appAccountVerify"
  }, {
    path: "/appEmailChange",
    component: _3fc1f275,
    name: "appEmailChange"
  }, {
    path: "/appPasswordReset",
    component: _4aee6986,
    name: "appPasswordReset"
  }, {
    path: "/auditLogs",
    component: _4f816aa0,
    name: "auditLogs"
  }, {
    path: "/bank_accounts",
    component: _3590945c,
    name: "bank_accounts"
  }, {
    path: "/banners",
    component: _784631e0,
    name: "banners"
  }, {
    path: "/batch_push_notification_reservations",
    component: _e96d0830,
    name: "batch_push_notification_reservations"
  }, {
    path: "/bug-reports",
    component: _0497ddef,
    name: "bug-reports"
  }, {
    path: "/bundle_rules",
    component: _5cde4867,
    name: "bundle_rules"
  }, {
    path: "/catalogs",
    component: _46fd91e7,
    name: "catalogs"
  }, {
    path: "/compensations",
    component: _04676ea2,
    name: "compensations"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/coupons",
    component: _54d7e454,
    name: "coupons"
  }, {
    path: "/dashboard",
    component: _794effcc,
    name: "dashboard"
  }, {
    path: "/debug",
    component: _cada89c8,
    name: "debug"
  }, {
    path: "/delivery_area_groups",
    component: _2c8f4028,
    name: "delivery_area_groups"
  }, {
    path: "/delivery_area_migrations",
    component: _7edb26f9,
    name: "delivery_area_migrations"
  }, {
    path: "/delivery_areas",
    component: _26df7528,
    name: "delivery_areas"
  }, {
    path: "/delivery_cars",
    component: _ae13a2da,
    name: "delivery_cars"
  }, {
    path: "/delivery_time_setting",
    component: _07fbdadc,
    name: "delivery_time_setting"
  }, {
    path: "/delivery_times",
    component: _743b0ae8,
    name: "delivery_times"
  }, {
    path: "/delivery_vouchers",
    component: _2c55fef9,
    name: "delivery_vouchers"
  }, {
    path: "/documents",
    component: _e9bed37e,
    name: "documents"
  }, {
    path: "/download_procurement_masters",
    component: _f537fc90,
    name: "download_procurement_masters"
  }, {
    path: "/ec_site_categories",
    component: _c7b565c0,
    name: "ec_site_categories"
  }, {
    path: "/ec_site_pickup_shops",
    component: _01d9e7fe,
    name: "ec_site_pickup_shops"
  }, {
    path: "/external_admin_users",
    component: _4dc5bd91,
    name: "external_admin_users"
  }, {
    path: "/features",
    component: _71c1faea,
    name: "features"
  }, {
    path: "/finalize_sale",
    component: _23646f5e,
    name: "finalize_sale"
  }, {
    path: "/health",
    component: _c4c89e74,
    name: "health"
  }, {
    path: "/loading",
    component: _7c9d4642,
    name: "loading"
  }, {
    path: "/master_datas",
    component: _081a90d9,
    name: "master_datas"
  }, {
    path: "/newPassword",
    component: _7824023e,
    name: "newPassword"
  }, {
    path: "/orders",
    component: _358c1412,
    name: "orders"
  }, {
    path: "/outgoingCallHistory",
    component: _de8fe360,
    name: "outgoingCallHistory"
  }, {
    path: "/payment_issues",
    component: _107068c0,
    name: "payment_issues"
  }, {
    path: "/picking_list",
    component: _0af18e2e,
    name: "picking_list"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products"
  }, {
    path: "/recoverEmail",
    component: _6e5c10fc,
    name: "recoverEmail"
  }, {
    path: "/reservation_products",
    component: _40bbafa4,
    name: "reservation_products"
  }, {
    path: "/sendPasswordReset",
    component: _3470a452,
    name: "sendPasswordReset"
  }, {
    path: "/shop_groups",
    component: _1e0b1906,
    name: "shop_groups"
  }, {
    path: "/signIn",
    component: _044611a8,
    name: "signIn"
  }, {
    path: "/signUp",
    component: _12734da2,
    name: "signUp"
  }, {
    path: "/stailer_standard_finalize_sale",
    component: _35a136e2,
    name: "stailer_standard_finalize_sale"
  }, {
    path: "/stock_arrivals",
    component: _324e9850,
    name: "stock_arrivals"
  }, {
    path: "/subscription_users",
    component: _9d784d5a,
    name: "subscription_users"
  }, {
    path: "/suppliers",
    component: _c0287620,
    name: "suppliers"
  }, {
    path: "/transaction_records",
    component: _9cd166ac,
    name: "transaction_records"
  }, {
    path: "/upload_product_images",
    component: _3128aa62,
    name: "upload_product_images"
  }, {
    path: "/users",
    component: _c91b56de,
    name: "users"
  }, {
    path: "/account_transfer/fail",
    component: _267fccb9,
    name: "account_transfer-fail"
  }, {
    path: "/account_transfer/requests",
    component: _e2d98442,
    name: "account_transfer-requests"
  }, {
    path: "/account_transfer/results",
    component: _7e5c68de,
    name: "account_transfer-results"
  }, {
    path: "/account_transfer/statements",
    component: _1fb6faff,
    name: "account_transfer-statements"
  }, {
    path: "/announcements/new",
    component: _e8944fba,
    name: "announcements-new"
  }, {
    path: "/batch_push_notification_reservations/new",
    component: _21e9a594,
    name: "batch_push_notification_reservations-new"
  }, {
    path: "/bug-reports/completed",
    component: _1d74fc68,
    name: "bug-reports-completed"
  }, {
    path: "/catalogs/new",
    component: _487ab6f5,
    name: "catalogs-new"
  }, {
    path: "/compensations/new",
    component: _0e9a8670,
    name: "compensations-new"
  }, {
    path: "/coupons/new",
    component: _9de53ab8,
    name: "coupons-new"
  }, {
    path: "/debug/bank_account_transfer",
    component: _61b373ad,
    name: "debug-bank_account_transfer"
  }, {
    path: "/debug/copy_order",
    component: _57d1042d,
    name: "debug-copy_order"
  }, {
    path: "/debug/finsih_pick_pack",
    component: _10a82c08,
    name: "debug-finsih_pick_pack"
  }, {
    path: "/delivery_area_groups/download_modal",
    component: _afd68060,
    name: "delivery_area_groups-download_modal"
  }, {
    path: "/delivery_area_groups/import_histories",
    component: _f3367996,
    name: "delivery_area_groups-import_histories"
  }, {
    path: "/delivery_area_groups/upload_modal",
    component: _18665a89,
    name: "delivery_area_groups-upload_modal"
  }, {
    path: "/delivery_area_migrations/create",
    component: _f783fa36,
    name: "delivery_area_migrations-create"
  }, {
    path: "/delivery_times/edit",
    component: _7d801b64,
    name: "delivery_times-edit"
  }, {
    path: "/delivery_vouchers/print_collecting_containers",
    component: _2363728e,
    name: "delivery_vouchers-print_collecting_containers"
  }, {
    path: "/delivery_vouchers/print_delivery_catalogs",
    component: _6d6cde3a,
    name: "delivery_vouchers-print_delivery_catalogs"
  }, {
    path: "/delivery_vouchers/print_delivery_route_instruction",
    component: _5c107608,
    name: "delivery_vouchers-print_delivery_route_instruction"
  }, {
    path: "/delivery_vouchers/print_normal_delivery_orders",
    component: _3e90cf9a,
    name: "delivery_vouchers-print_normal_delivery_orders"
  }, {
    path: "/delivery_vouchers/print_receipts",
    component: _65967ef6,
    name: "delivery_vouchers-print_receipts"
  }, {
    path: "/delivery_vouchers/print_scheduled_delivery_orders",
    component: _07e9b753,
    name: "delivery_vouchers-print_scheduled_delivery_orders"
  }, {
    path: "/delivery_vouchers/print_scheduled_receipts",
    component: _39fb8cf0,
    name: "delivery_vouchers-print_scheduled_receipts"
  }, {
    path: "/documents/new",
    component: _566709cf,
    name: "documents-new"
  }, {
    path: "/ec_site_shops/register",
    component: _a0506d14,
    name: "ec_site_shops-register"
  }, {
    path: "/external_admin_users/create",
    component: _dab38166,
    name: "external_admin_users-create"
  }, {
    path: "/external_admin_users/invite",
    component: _ef1a024c,
    name: "external_admin_users-invite"
  }, {
    path: "/fulfillment/product_arrangement",
    component: _7fa9845c,
    name: "fulfillment-product_arrangement"
  }, {
    path: "/master_datas/new",
    component: _2e4eca67,
    name: "master_datas-new"
  }, {
    path: "/master_datas/upload",
    component: _7a5b90ec,
    name: "master_datas-upload"
  }, {
    path: "/orders/create_sheet",
    component: _24896cf9,
    name: "orders-create_sheet"
  }, {
    path: "/orders/order_helper",
    component: _1608b0b6,
    name: "orders-order_helper"
  }, {
    path: "/orders/print",
    component: _0adb8f26,
    name: "orders-print"
  }, {
    path: "/orders/print_order_list",
    component: _482a4731,
    name: "orders-print_order_list"
  }, {
    path: "/orders/products",
    component: _12a89252,
    name: "orders-products"
  }, {
    path: "/picking_list/print",
    component: _80e54578,
    name: "picking_list-print"
  }, {
    path: "/products/edit",
    component: _646611db,
    name: "products-edit"
  }, {
    path: "/recurring_order_settings/users",
    component: _c7631e08,
    name: "recurring_order_settings-users"
  }, {
    path: "/recurring_orders/payment_faileds",
    component: _60c035c2,
    name: "recurring_orders-payment_faileds"
  }, {
    path: "/suppliers/new",
    component: _e787cf84,
    name: "suppliers-new"
  }, {
    path: "/debug/register_debug_delivery/create",
    component: _a88de8d6,
    name: "debug-register_debug_delivery-create"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/public/announcements/:id?",
    component: _276c4e7b,
    name: "public-announcements-id"
  }, {
    path: "/public/documents/:id?",
    component: _3c10cf27,
    name: "public-documents-id"
  }, {
    path: "/public/terms/:id?",
    component: _ed770614,
    name: "public-terms-id"
  }, {
    path: "/recurring_order_settings/users/:userId",
    component: _4081165c,
    name: "recurring_order_settings-users-userId"
  }, {
    path: "/recurring_orders/payment_faileds/:id",
    component: _41f67eac,
    name: "recurring_orders-payment_faileds-id"
  }, {
    path: "/account_transfer/statements/:statementId/print",
    component: _202b7728,
    name: "account_transfer-statements-statementId-print"
  }, {
    path: "/recurring_order_settings/users/:userId/edit",
    component: _6831cc3a,
    name: "recurring_order_settings-users-userId-edit"
  }, {
    path: "/banners/:id",
    component: _54996d78,
    name: "banners-id"
  }, {
    path: "/catalogs/:id",
    component: _34c4e20f,
    name: "catalogs-id"
  }, {
    path: "/contacts/:id",
    component: _c0a89430,
    name: "contacts-id"
  }, {
    path: "/delivery_area_groups/:delivery_area_group_id",
    component: _03820692,
    name: "delivery_area_groups-delivery_area_group_id"
  }, {
    path: "/delivery_area_migrations/:id",
    component: _426ed8be,
    name: "delivery_area_migrations-id"
  }, {
    path: "/delivery_cars/:id",
    component: _09568c8a,
    name: "delivery_cars-id"
  }, {
    path: "/delivery_time_setting/:id",
    component: _4890dd0c,
    name: "delivery_time_setting-id"
  }, {
    path: "/features/:id",
    component: _4c85afd2,
    name: "features-id"
  }, {
    path: "/orders/:orderId",
    component: _716826ba,
    name: "orders-orderId"
  }, {
    path: "/reservation_products/:id",
    component: _ac0d69e8,
    name: "reservation_products-id"
  }, {
    path: "/subscription_users/:id",
    component: _6c8e147b,
    name: "subscription_users-id"
  }, {
    path: "/suppliers/:id",
    component: _78864358,
    name: "suppliers-id"
  }, {
    path: "/transaction_records/:id",
    component: _9df71cdc,
    name: "transaction_records-id"
  }, {
    path: "/users/:id",
    component: _1c03718e,
    name: "users-id"
  }, {
    path: "/announcements/:id/edit",
    component: _46f4bb4c,
    name: "announcements-id-edit"
  }, {
    path: "/batch_push_notification_reservations/:id/edit",
    component: _45cecbce,
    name: "batch_push_notification_reservations-id-edit"
  }, {
    path: "/bundle_rules/:id/edit",
    component: _0ffa093a,
    name: "bundle_rules-id-edit"
  }, {
    path: "/coupons/:id/edit",
    component: _38e4996b,
    name: "coupons-id-edit"
  }, {
    path: "/documents/:id/edit",
    component: _45dbb520,
    name: "documents-id-edit"
  }, {
    path: "/ec_site_pickup_shops/:id/edit",
    component: _4daaae43,
    name: "ec_site_pickup_shops-id-edit"
  }, {
    path: "/ec_site_shops/:id?/edit",
    component: _07193e12,
    name: "ec_site_shops-id-edit"
  }, {
    path: "/master_datas/:name/edit",
    component: _0edca0d0,
    name: "master_datas-name-edit"
  }, {
    path: "/master_datas/:name/files",
    component: _3e143459,
    name: "master_datas-name-files"
  }, {
    path: "/orders/:id/compensation_entries",
    component: _84736260,
    name: "orders-id-compensation_entries"
  }, {
    path: "/orders/:id/compensation_invoice",
    component: _03e11226,
    name: "orders-id-compensation_invoice"
  }, {
    path: "/users/:userId/bank_account",
    component: _1a4dd125,
    name: "users-userId-bank_account"
  }, {
    path: "/users/:userId/bank_accounts/new",
    component: _25b2cfa2,
    name: "users-userId-bank_accounts-new"
  }, {
    path: "/users/:userId/bank_accounts/manual_transfers/new",
    component: _9c54587e,
    name: "users-userId-bank_accounts-manual_transfers-new"
  }, {
    path: "/subscription_users/:userId/plans/:id",
    component: _7d73666f,
    name: "subscription_users-userId-plans-id"
  }, {
    path: "/users/:userId/bank_account_transfer_summaries/:bankAccountTransferSummaryId?",
    component: _74ddfcef,
    name: "users-userId-bank_account_transfer_summaries-bankAccountTransferSummaryId"
  }, {
    path: "/catalogs/:catalogId/pages/:pageId?/edit",
    component: _858c222c,
    name: "catalogs-catalogId-pages-pageId-edit"
  }, {
    path: "/subscription_users/:userId/plans/:id?/edit",
    component: _90ca9a06,
    name: "subscription_users-userId-plans-id-edit"
  }, {
    path: "/users/:userId/bank_accounts/:bankAccountId?/edit",
    component: _2763b3ca,
    name: "users-userId-bank_accounts-bankAccountId-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
