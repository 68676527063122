// source: type/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_tax_type_pb = require('../type/tax_type_pb.js');
goog.object.extend(proto, type_tax_type_pb);
var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_tax_calculation_result_pb = require('../type/tax_calculation_result_pb.js');
goog.object.extend(proto, type_tax_calculation_result_pb);
var type_orderer_info_pb = require('../type/orderer_info_pb.js');
goog.object.extend(proto, type_orderer_info_pb);
var type_coupon_pb = require('../type/coupon_pb.js');
goog.object.extend(proto, type_coupon_pb);
var type_price_pb = require('../type/price_pb.js');
goog.object.extend(proto, type_price_pb);
var type_shipping_info_pb = require('../type/shipping_info_pb.js');
goog.object.extend(proto, type_shipping_info_pb);
var type_subscription_type_pb = require('../type/subscription_type_pb.js');
goog.object.extend(proto, type_subscription_type_pb);
var type_order_recipient_info_pb = require('../type/order_recipient_info_pb.js');
goog.object.extend(proto, type_order_recipient_info_pb);
var type_order_receive_type_pb = require('../type/order_receive_type_pb.js');
goog.object.extend(proto, type_order_receive_type_pb);
var type_container_type_pb = require('../type/container_type_pb.js');
goog.object.extend(proto, type_container_type_pb);
var type_order_bundle_pb = require('../type/order_bundle_pb.js');
goog.object.extend(proto, type_order_bundle_pb);
var type_order_total_price_pb = require('../type/order_total_price_pb.js');
goog.object.extend(proto, type_order_total_price_pb);
var type_qualified_invoice_issuer_pb = require('../type/qualified_invoice_issuer_pb.js');
goog.object.extend(proto, type_qualified_invoice_issuer_pb);
goog.exportSymbol('proto.type.Order', null, global);
goog.exportSymbol('proto.type.Order.CarBodyType', null, global);
goog.exportSymbol('proto.type.Order.CarColorType', null, global);
goog.exportSymbol('proto.type.Order.Container', null, global);
goog.exportSymbol('proto.type.Order.DeliveryOnHoldReason', null, global);
goog.exportSymbol('proto.type.Order.DeliveryType', null, global);
goog.exportSymbol('proto.type.Order.Discount', null, global);
goog.exportSymbol('proto.type.Order.DiscountType', null, global);
goog.exportSymbol('proto.type.Order.Fee', null, global);
goog.exportSymbol('proto.type.Order.FeeType', null, global);
goog.exportSymbol('proto.type.Order.FinalTotalPrice', null, global);
goog.exportSymbol('proto.type.Order.Payment', null, global);
goog.exportSymbol('proto.type.Order.PaymentMethod', null, global);
goog.exportSymbol('proto.type.Order.PickupInformationCase', null, global);
goog.exportSymbol('proto.type.Order.Product', null, global);
goog.exportSymbol('proto.type.Order.Product.PricingMethod', null, global);
goog.exportSymbol('proto.type.Order.Product.PricingMethod.FixedPricing', null, global);
goog.exportSymbol('proto.type.Order.Product.PricingMethod.WeightBasedPricing', null, global);
goog.exportSymbol('proto.type.Order.Product.PricingMethodCase', null, global);
goog.exportSymbol('proto.type.Order.Status', null, global);
goog.exportSymbol('proto.type.Order.StockOutProduct', null, global);
goog.exportSymbol('proto.type.PaymentStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.Order.repeatedFields_, proto.type.Order.oneofGroups_);
};
goog.inherits(proto.type.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.displayName = 'proto.type.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Payment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Payment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Payment.displayName = 'proto.type.Order.Payment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Discount.displayName = 'proto.type.Order.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Fee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Fee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Fee.displayName = 'proto.type.Order.Fee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.FinalTotalPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.Order.FinalTotalPrice.repeatedFields_, null);
};
goog.inherits(proto.type.Order.FinalTotalPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.FinalTotalPrice.displayName = 'proto.type.Order.FinalTotalPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.type.Order.Product.oneofGroups_);
};
goog.inherits(proto.type.Order.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Product.displayName = 'proto.type.Order.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Product.PricingMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Product.PricingMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Product.PricingMethod.displayName = 'proto.type.Order.Product.PricingMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Product.PricingMethod.FixedPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Product.PricingMethod.FixedPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Product.PricingMethod.FixedPricing.displayName = 'proto.type.Order.Product.PricingMethod.FixedPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Product.PricingMethod.WeightBasedPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Product.PricingMethod.WeightBasedPricing.displayName = 'proto.type.Order.Product.PricingMethod.WeightBasedPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.StockOutProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.StockOutProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.StockOutProduct.displayName = 'proto.type.Order.StockOutProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Order.Container = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Order.Container, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Order.Container.displayName = 'proto.type.Order.Container';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.Order.repeatedFields_ = [2,11,33,51,62,83,85,87];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.Order.oneofGroups_ = [[63,64,65]];

/**
 * @enum {number}
 */
proto.type.Order.PickupInformationCase = {
  PICKUP_INFORMATION_NOT_SET: 0,
  PICKUP_SHOP_INFORMATION: 63,
  PICKUP_CAR_INFORMATION: 64,
  PICKUP_LOCKER_INFORMATION: 65
};

/**
 * @return {proto.type.Order.PickupInformationCase}
 */
proto.type.Order.prototype.getPickupInformationCase = function() {
  return /** @type {proto.type.Order.PickupInformationCase} */(jspb.Message.computeOneofCase(this, proto.type.Order.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deprecatedProductsList: jspb.Message.toObjectList(msg.getDeprecatedProductsList(),
    proto.type.Order.Product.toObject, includeInstance),
    deliveryFrom: (f = msg.getDeliveryFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryTo: (f = msg.getDeliveryTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    changeDeadline: (f = msg.getChangeDeadline()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orderedAt: (f = msg.getOrderedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isChangeable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isCancelable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    inTaxTotalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    inTaxDeliveryFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    deprecatedStockOutProductsList: jspb.Message.toObjectList(msg.getDeprecatedStockOutProductsList(),
    proto.type.Order.StockOutProduct.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    deliveryTimeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    receiveType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    carBodyType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    carColorType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    receivedAt: (f = msg.getReceivedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preparedAt: (f = msg.getPreparedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isApproaching: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    isArrived: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    earnedPoint: jspb.Message.getFieldWithDefault(msg, 22, 0),
    headingAt: (f = msg.getHeadingAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    arrivedAt: (f = msg.getArrivedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    canceledAt: (f = msg.getCanceledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveredAt: (f = msg.getDeliveredAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 27, 0),
    inTaxSubTotalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    inTaxShoppingBagFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    ecSitePickupShop: (f = msg.getEcSitePickupShop()) && type_ec_site_pb.EcSitePickupShop.toObject(includeInstance, f),
    noTaxDeliveryFee: jspb.Message.getFieldWithDefault(msg, 31, 0),
    noTaxSubTotalPrice: jspb.Message.getFieldWithDefault(msg, 32, 0),
    taxCalculationResultsList: jspb.Message.toObjectList(msg.getTaxCalculationResultsList(),
    type_tax_calculation_result_pb.TaxCalculationResult.toObject, includeInstance),
    subTotalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    userId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    userRequest: jspb.Message.getFieldWithDefault(msg, 37, ""),
    requirePaperReceipt: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    nonContactDelivery: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    ecSiteShop: (f = msg.getEcSiteShop()) && type_ec_site_pb.EcSiteShop.toObject(includeInstance, f),
    ordererInfo: (f = msg.getOrdererInfo()) && type_orderer_info_pb.OrdererInfo.toObject(includeInstance, f),
    redeemedPoint: jspb.Message.getFieldWithDefault(msg, 42, 0),
    requestAlternativeItems: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    pointCardNumber: jspb.Message.getFieldWithDefault(msg, 44, ""),
    appliedCoupon: (f = msg.getAppliedCoupon()) && type_coupon_pb.AppliedCoupon.toObject(includeInstance, f),
    couponDiscount: jspb.Message.getFieldWithDefault(msg, 46, 0),
    chargeAmount: jspb.Message.getFieldWithDefault(msg, 47, 0),
    deprecatedDeliveryOnHoldReason: jspb.Message.getFieldWithDefault(msg, 48, ""),
    deliveryFee: jspb.Message.getFieldWithDefault(msg, 49, 0),
    deliveryFeeType: jspb.Message.getFieldWithDefault(msg, 50, 0),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.type.Order.Product.toObject, includeInstance),
    shippingInfo: (f = msg.getShippingInfo()) && type_shipping_info_pb.ShippingInfo.toObject(includeInstance, f),
    deliveryOnHoldReason: jspb.Message.getFieldWithDefault(msg, 53, 0),
    isAppliedFreeDeliverySubscription: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
    labelNumber: jspb.Message.getFieldWithDefault(msg, 55, ""),
    isExclusiveDelivery: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
    orderRecipientInfo: (f = msg.getOrderRecipientInfo()) && type_order_recipient_info_pb.OrderRecipientInfo.toObject(includeInstance, f),
    earnedRegularPoint: jspb.Message.getFieldWithDefault(msg, 58, 0),
    earnedBonusPoint: jspb.Message.getFieldWithDefault(msg, 59, 0),
    deprecatedContainerCount: jspb.Message.getFieldWithDefault(msg, 60, 0),
    containsProductsOf20AgeRestriction: jspb.Message.getBooleanFieldWithDefault(msg, 61, false),
    containersList: jspb.Message.toObjectList(msg.getContainersList(),
    proto.type.Order.Container.toObject, includeInstance),
    pickupShopInformation: (f = msg.getPickupShopInformation()) && type_ec_site_pb.PickupShopInformation.toObject(includeInstance, f),
    pickupCarInformation: (f = msg.getPickupCarInformation()) && type_ec_site_pb.PickupCarInformation.toObject(includeInstance, f),
    pickupLockerInformation: (f = msg.getPickupLockerInformation()) && type_ec_site_pb.PickupLockerInformation.toObject(includeInstance, f),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 66, 0),
    putOnHoldAt: (f = msg.getPutOnHoldAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isChangedDeliveryTimeByStaff: jspb.Message.getBooleanFieldWithDefault(msg, 68, false),
    corporateOrderSummaryId: jspb.Message.getFieldWithDefault(msg, 69, ""),
    cashOnDeliveryFee: jspb.Message.getFieldWithDefault(msg, 70, 0),
    cashOnDeliveryFeeType: jspb.Message.getFieldWithDefault(msg, 71, 0),
    creditCardId: jspb.Message.getFieldWithDefault(msg, 72, ""),
    pictureProofOfDeliveryUrl: jspb.Message.getFieldWithDefault(msg, 73, ""),
    photoOnDeliveryUrl: jspb.Message.getFieldWithDefault(msg, 74, ""),
    isPointCardLinked: jspb.Message.getBooleanFieldWithDefault(msg, 75, false),
    orderBundle: (f = msg.getOrderBundle()) && type_order_bundle_pb.OrderBundle.toObject(includeInstance, f),
    orderTotalPrice: (f = msg.getOrderTotalPrice()) && type_order_total_price_pb.OrderTotalPrice.toObject(includeInstance, f),
    deliveryTimeIsChangeable: jspb.Message.getBooleanFieldWithDefault(msg, 78, false),
    qualifiedInvoiceIssuer: (f = msg.getQualifiedInvoiceIssuer()) && type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer.toObject(includeInstance, f),
    invoiceFinalizedAt: (f = msg.getInvoiceFinalizedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isInvoiceFinalized: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    labelNumberPrefix: jspb.Message.getFieldWithDefault(msg, 82, ""),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.type.Order.Fee.toObject, includeInstance),
    preDiscountTotalAmount: jspb.Message.getFieldWithDefault(msg, 84, 0),
    discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
    proto.type.Order.Discount.toObject, includeInstance),
    finalTotalPrice: (f = msg.getFinalTotalPrice()) && proto.type.Order.FinalTotalPrice.toObject(includeInstance, f),
    paymentsList: jspb.Message.toObjectList(msg.getPaymentsList(),
    proto.type.Order.Payment.toObject, includeInstance),
    isCompensated: jspb.Message.getBooleanFieldWithDefault(msg, 88, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Order as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderId;

  /** @type {?|undefined} */
  this.deprecatedProductsList;

  /** @type {?|undefined} */
  this.deliveryFrom;

  /** @type {?|undefined} */
  this.deliveryTo;

  /** @type {?|undefined} */
  this.changeDeadline;

  /** @type {?|undefined} */
  this.orderedAt;

  /** @type {?|undefined} */
  this.isChangeable;

  /** @type {?|undefined} */
  this.isCancelable;

  /** @type {?|undefined} */
  this.inTaxTotalPrice;

  /** @type {?|undefined} */
  this.inTaxDeliveryFee;

  /** @type {?|undefined} */
  this.deprecatedStockOutProductsList;

  /** @type {?|undefined} */
  this.status;

  /** @type {?|undefined} */
  this.deliveryTimeId;

  /** @type {?|undefined} */
  this.shopName;

  /** @type {?|undefined} */
  this.receiveType;

  /** @type {?|undefined} */
  this.carBodyType;

  /** @type {?|undefined} */
  this.carColorType;

  /** @type {?|undefined} */
  this.receivedAt;

  /** @type {?|undefined} */
  this.preparedAt;

  /** @type {?|undefined} */
  this.isApproaching;

  /** @type {?|undefined} */
  this.isArrived;

  /** @type {?|undefined} */
  this.earnedPoint;

  /** @type {?|undefined} */
  this.headingAt;

  /** @type {?|undefined} */
  this.arrivedAt;

  /** @type {?|undefined} */
  this.canceledAt;

  /** @type {?|undefined} */
  this.deliveredAt;

  /** @type {?|undefined} */
  this.paymentMethod;

  /** @type {?|undefined} */
  this.inTaxSubTotalPrice;

  /** @type {?|undefined} */
  this.inTaxShoppingBagFee;

  /** @type {?|undefined} */
  this.ecSitePickupShop;

  /** @type {?|undefined} */
  this.noTaxDeliveryFee;

  /** @type {?|undefined} */
  this.noTaxSubTotalPrice;

  /** @type {?|undefined} */
  this.taxCalculationResultsList;

  /** @type {?|undefined} */
  this.subTotalPrice;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.userId;

  /** @type {?|undefined} */
  this.userRequest;

  /** @type {?|undefined} */
  this.requirePaperReceipt;

  /** @type {?|undefined} */
  this.nonContactDelivery;

  /** @type {?|undefined} */
  this.ecSiteShop;

  /** @type {?|undefined} */
  this.ordererInfo;

  /** @type {?|undefined} */
  this.redeemedPoint;

  /** @type {?|undefined} */
  this.requestAlternativeItems;

  /** @type {?|undefined} */
  this.pointCardNumber;

  /** @type {?|undefined} */
  this.appliedCoupon;

  /** @type {?|undefined} */
  this.couponDiscount;

  /** @type {?|undefined} */
  this.chargeAmount;

  /** @type {?|undefined} */
  this.deprecatedDeliveryOnHoldReason;

  /** @type {?|undefined} */
  this.deliveryFee;

  /** @type {?|undefined} */
  this.deliveryFeeType;

  /** @type {?|undefined} */
  this.productsList;

  /** @type {?|undefined} */
  this.shippingInfo;

  /** @type {?|undefined} */
  this.deliveryOnHoldReason;

  /** @type {?|undefined} */
  this.isAppliedFreeDeliverySubscription;

  /** @type {?|undefined} */
  this.labelNumber;

  /** @type {?|undefined} */
  this.isExclusiveDelivery;

  /** @type {?|undefined} */
  this.orderRecipientInfo;

  /** @type {?|undefined} */
  this.earnedRegularPoint;

  /** @type {?|undefined} */
  this.earnedBonusPoint;

  /** @type {?|undefined} */
  this.deprecatedContainerCount;

  /** @type {?|undefined} */
  this.containsProductsOf20AgeRestriction;

  /** @type {?|undefined} */
  this.containersList;

  /** @type {?|undefined} */
  this.pickupShopInformation;

  /** @type {?|undefined} */
  this.pickupCarInformation;

  /** @type {?|undefined} */
  this.pickupLockerInformation;

  /** @type {?|undefined} */
  this.paymentStatus;

  /** @type {?|undefined} */
  this.putOnHoldAt;

  /** @type {?|undefined} */
  this.isChangedDeliveryTimeByStaff;

  /** @type {?|undefined} */
  this.corporateOrderSummaryId;

  /** @type {?|undefined} */
  this.cashOnDeliveryFee;

  /** @type {?|undefined} */
  this.cashOnDeliveryFeeType;

  /** @type {?|undefined} */
  this.creditCardId;

  /** @type {?|undefined} */
  this.pictureProofOfDeliveryUrl;

  /** @type {?|undefined} */
  this.photoOnDeliveryUrl;

  /** @type {?|undefined} */
  this.isPointCardLinked;

  /** @type {?|undefined} */
  this.orderBundle;

  /** @type {?|undefined} */
  this.orderTotalPrice;

  /** @type {?|undefined} */
  this.deliveryTimeIsChangeable;

  /** @type {?|undefined} */
  this.qualifiedInvoiceIssuer;

  /** @type {?|undefined} */
  this.invoiceFinalizedAt;

  /** @type {?|undefined} */
  this.isInvoiceFinalized;

  /** @type {?|undefined} */
  this.labelNumberPrefix;

  /** @type {?|undefined} */
  this.feesList;

  /** @type {?|undefined} */
  this.preDiscountTotalAmount;

  /** @type {?|undefined} */
  this.discountsList;

  /** @type {?|undefined} */
  this.finalTotalPrice;

  /** @type {?|undefined} */
  this.paymentsList;

  /** @type {?|undefined} */
  this.isCompensated;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order}
 */
proto.type.Order.fromObject = function(obj) {
  var msg = new proto.type.Order();
  obj.orderId != null && jspb.Message.setField(msg, 1, obj.orderId);
  obj.deprecatedProductsList && jspb.Message.setRepeatedWrapperField(
      msg, 2, obj.deprecatedProductsList.map(
          proto.type.Order.Product.fromObject));
  obj.deliveryFrom && jspb.Message.setWrapperField(
      msg, 3, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryFrom));
  obj.deliveryTo && jspb.Message.setWrapperField(
      msg, 4, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryTo));
  obj.changeDeadline && jspb.Message.setWrapperField(
      msg, 5, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.changeDeadline));
  obj.orderedAt && jspb.Message.setWrapperField(
      msg, 6, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.orderedAt));
  obj.isChangeable != null && jspb.Message.setField(msg, 7, obj.isChangeable);
  obj.isCancelable != null && jspb.Message.setField(msg, 8, obj.isCancelable);
  obj.inTaxTotalPrice != null && jspb.Message.setField(msg, 9, obj.inTaxTotalPrice);
  obj.inTaxDeliveryFee != null && jspb.Message.setField(msg, 10, obj.inTaxDeliveryFee);
  obj.deprecatedStockOutProductsList && jspb.Message.setRepeatedWrapperField(
      msg, 11, obj.deprecatedStockOutProductsList.map(
          proto.type.Order.StockOutProduct.fromObject));
  obj.status != null && jspb.Message.setField(msg, 12, obj.status);
  obj.deliveryTimeId != null && jspb.Message.setField(msg, 13, obj.deliveryTimeId);
  obj.shopName != null && jspb.Message.setField(msg, 14, obj.shopName);
  obj.receiveType != null && jspb.Message.setField(msg, 15, obj.receiveType);
  obj.carBodyType != null && jspb.Message.setField(msg, 16, obj.carBodyType);
  obj.carColorType != null && jspb.Message.setField(msg, 17, obj.carColorType);
  obj.receivedAt && jspb.Message.setWrapperField(
      msg, 18, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.receivedAt));
  obj.preparedAt && jspb.Message.setWrapperField(
      msg, 19, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.preparedAt));
  obj.isApproaching != null && jspb.Message.setField(msg, 20, obj.isApproaching);
  obj.isArrived != null && jspb.Message.setField(msg, 21, obj.isArrived);
  obj.earnedPoint != null && jspb.Message.setField(msg, 22, obj.earnedPoint);
  obj.headingAt && jspb.Message.setWrapperField(
      msg, 23, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.headingAt));
  obj.arrivedAt && jspb.Message.setWrapperField(
      msg, 24, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.arrivedAt));
  obj.canceledAt && jspb.Message.setWrapperField(
      msg, 25, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.canceledAt));
  obj.deliveredAt && jspb.Message.setWrapperField(
      msg, 26, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveredAt));
  obj.paymentMethod != null && jspb.Message.setField(msg, 27, obj.paymentMethod);
  obj.inTaxSubTotalPrice != null && jspb.Message.setField(msg, 28, obj.inTaxSubTotalPrice);
  obj.inTaxShoppingBagFee != null && jspb.Message.setField(msg, 29, obj.inTaxShoppingBagFee);
  obj.ecSitePickupShop && jspb.Message.setWrapperField(
      msg, 30, type_ec_site_pb.EcSitePickupShop.fromObject(obj.ecSitePickupShop));
  obj.noTaxDeliveryFee != null && jspb.Message.setField(msg, 31, obj.noTaxDeliveryFee);
  obj.noTaxSubTotalPrice != null && jspb.Message.setField(msg, 32, obj.noTaxSubTotalPrice);
  obj.taxCalculationResultsList && jspb.Message.setRepeatedWrapperField(
      msg, 33, obj.taxCalculationResultsList.map(
          type_tax_calculation_result_pb.TaxCalculationResult.fromObject));
  obj.subTotalPrice != null && jspb.Message.setField(msg, 34, obj.subTotalPrice);
  obj.totalPrice != null && jspb.Message.setField(msg, 35, obj.totalPrice);
  obj.userId != null && jspb.Message.setField(msg, 36, obj.userId);
  obj.userRequest != null && jspb.Message.setField(msg, 37, obj.userRequest);
  obj.requirePaperReceipt != null && jspb.Message.setField(msg, 38, obj.requirePaperReceipt);
  obj.nonContactDelivery != null && jspb.Message.setField(msg, 39, obj.nonContactDelivery);
  obj.ecSiteShop && jspb.Message.setWrapperField(
      msg, 40, type_ec_site_pb.EcSiteShop.fromObject(obj.ecSiteShop));
  obj.ordererInfo && jspb.Message.setWrapperField(
      msg, 41, type_orderer_info_pb.OrdererInfo.fromObject(obj.ordererInfo));
  obj.redeemedPoint != null && jspb.Message.setField(msg, 42, obj.redeemedPoint);
  obj.requestAlternativeItems != null && jspb.Message.setField(msg, 43, obj.requestAlternativeItems);
  obj.pointCardNumber != null && jspb.Message.setField(msg, 44, obj.pointCardNumber);
  obj.appliedCoupon && jspb.Message.setWrapperField(
      msg, 45, type_coupon_pb.AppliedCoupon.fromObject(obj.appliedCoupon));
  obj.couponDiscount != null && jspb.Message.setField(msg, 46, obj.couponDiscount);
  obj.chargeAmount != null && jspb.Message.setField(msg, 47, obj.chargeAmount);
  obj.deprecatedDeliveryOnHoldReason != null && jspb.Message.setField(msg, 48, obj.deprecatedDeliveryOnHoldReason);
  obj.deliveryFee != null && jspb.Message.setField(msg, 49, obj.deliveryFee);
  obj.deliveryFeeType != null && jspb.Message.setField(msg, 50, obj.deliveryFeeType);
  obj.productsList && jspb.Message.setRepeatedWrapperField(
      msg, 51, obj.productsList.map(
          proto.type.Order.Product.fromObject));
  obj.shippingInfo && jspb.Message.setWrapperField(
      msg, 52, type_shipping_info_pb.ShippingInfo.fromObject(obj.shippingInfo));
  obj.deliveryOnHoldReason != null && jspb.Message.setField(msg, 53, obj.deliveryOnHoldReason);
  obj.isAppliedFreeDeliverySubscription != null && jspb.Message.setField(msg, 54, obj.isAppliedFreeDeliverySubscription);
  obj.labelNumber != null && jspb.Message.setField(msg, 55, obj.labelNumber);
  obj.isExclusiveDelivery != null && jspb.Message.setField(msg, 56, obj.isExclusiveDelivery);
  obj.orderRecipientInfo && jspb.Message.setWrapperField(
      msg, 57, type_order_recipient_info_pb.OrderRecipientInfo.fromObject(obj.orderRecipientInfo));
  obj.earnedRegularPoint != null && jspb.Message.setField(msg, 58, obj.earnedRegularPoint);
  obj.earnedBonusPoint != null && jspb.Message.setField(msg, 59, obj.earnedBonusPoint);
  obj.deprecatedContainerCount != null && jspb.Message.setField(msg, 60, obj.deprecatedContainerCount);
  obj.containsProductsOf20AgeRestriction != null && jspb.Message.setField(msg, 61, obj.containsProductsOf20AgeRestriction);
  obj.containersList && jspb.Message.setRepeatedWrapperField(
      msg, 62, obj.containersList.map(
          proto.type.Order.Container.fromObject));
  obj.pickupShopInformation && jspb.Message.setWrapperField(
      msg, 63, type_ec_site_pb.PickupShopInformation.fromObject(obj.pickupShopInformation));
  obj.pickupCarInformation && jspb.Message.setWrapperField(
      msg, 64, type_ec_site_pb.PickupCarInformation.fromObject(obj.pickupCarInformation));
  obj.pickupLockerInformation && jspb.Message.setWrapperField(
      msg, 65, type_ec_site_pb.PickupLockerInformation.fromObject(obj.pickupLockerInformation));
  obj.paymentStatus != null && jspb.Message.setField(msg, 66, obj.paymentStatus);
  obj.putOnHoldAt && jspb.Message.setWrapperField(
      msg, 67, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.putOnHoldAt));
  obj.isChangedDeliveryTimeByStaff != null && jspb.Message.setField(msg, 68, obj.isChangedDeliveryTimeByStaff);
  obj.corporateOrderSummaryId != null && jspb.Message.setField(msg, 69, obj.corporateOrderSummaryId);
  obj.cashOnDeliveryFee != null && jspb.Message.setField(msg, 70, obj.cashOnDeliveryFee);
  obj.cashOnDeliveryFeeType != null && jspb.Message.setField(msg, 71, obj.cashOnDeliveryFeeType);
  obj.creditCardId != null && jspb.Message.setField(msg, 72, obj.creditCardId);
  obj.pictureProofOfDeliveryUrl != null && jspb.Message.setField(msg, 73, obj.pictureProofOfDeliveryUrl);
  obj.photoOnDeliveryUrl != null && jspb.Message.setField(msg, 74, obj.photoOnDeliveryUrl);
  obj.isPointCardLinked != null && jspb.Message.setField(msg, 75, obj.isPointCardLinked);
  obj.orderBundle && jspb.Message.setWrapperField(
      msg, 76, type_order_bundle_pb.OrderBundle.fromObject(obj.orderBundle));
  obj.orderTotalPrice && jspb.Message.setWrapperField(
      msg, 77, type_order_total_price_pb.OrderTotalPrice.fromObject(obj.orderTotalPrice));
  obj.deliveryTimeIsChangeable != null && jspb.Message.setField(msg, 78, obj.deliveryTimeIsChangeable);
  obj.qualifiedInvoiceIssuer && jspb.Message.setWrapperField(
      msg, 79, type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer.fromObject(obj.qualifiedInvoiceIssuer));
  obj.invoiceFinalizedAt && jspb.Message.setWrapperField(
      msg, 80, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.invoiceFinalizedAt));
  obj.isInvoiceFinalized != null && jspb.Message.setField(msg, 81, obj.isInvoiceFinalized);
  obj.labelNumberPrefix != null && jspb.Message.setField(msg, 82, obj.labelNumberPrefix);
  obj.feesList && jspb.Message.setRepeatedWrapperField(
      msg, 83, obj.feesList.map(
          proto.type.Order.Fee.fromObject));
  obj.preDiscountTotalAmount != null && jspb.Message.setField(msg, 84, obj.preDiscountTotalAmount);
  obj.discountsList && jspb.Message.setRepeatedWrapperField(
      msg, 85, obj.discountsList.map(
          proto.type.Order.Discount.fromObject));
  obj.finalTotalPrice && jspb.Message.setWrapperField(
      msg, 86, proto.type.Order.FinalTotalPrice.fromObject(obj.finalTotalPrice));
  obj.paymentsList && jspb.Message.setRepeatedWrapperField(
      msg, 87, obj.paymentsList.map(
          proto.type.Order.Payment.fromObject));
  obj.isCompensated != null && jspb.Message.setField(msg, 88, obj.isCompensated);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order}
 */
proto.type.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order;
  return proto.type.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order}
 */
proto.type.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new proto.type.Order.Product;
      reader.readMessage(value,proto.type.Order.Product.deserializeBinaryFromReader);
      msg.addDeprecatedProducts(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryTo(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeDeadline(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderedAt(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsChangeable(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCancelable(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxTotalPrice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxDeliveryFee(value);
      break;
    case 11:
      var value = new proto.type.Order.StockOutProduct;
      reader.readMessage(value,proto.type.Order.StockOutProduct.deserializeBinaryFromReader);
      msg.addDeprecatedStockOutProducts(value);
      break;
    case 12:
      var value = /** @type {!proto.type.Order.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryTimeId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 15:
      var value = /** @type {!proto.type.OrderReceiveKind} */ (reader.readEnum());
      msg.setReceiveType(value);
      break;
    case 16:
      var value = /** @type {!proto.type.Order.CarBodyType} */ (reader.readEnum());
      msg.setCarBodyType(value);
      break;
    case 17:
      var value = /** @type {!proto.type.Order.CarColorType} */ (reader.readEnum());
      msg.setCarColorType(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedAt(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreparedAt(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApproaching(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArrived(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEarnedPoint(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHeadingAt(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArrivedAt(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCanceledAt(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveredAt(value);
      break;
    case 27:
      var value = /** @type {!proto.type.Order.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentMethod(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxSubTotalPrice(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxShoppingBagFee(value);
      break;
    case 30:
      var value = new type_ec_site_pb.EcSitePickupShop;
      reader.readMessage(value,type_ec_site_pb.EcSitePickupShop.deserializeBinaryFromReader);
      msg.setEcSitePickupShop(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxDeliveryFee(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxSubTotalPrice(value);
      break;
    case 33:
      var value = new type_tax_calculation_result_pb.TaxCalculationResult;
      reader.readMessage(value,type_tax_calculation_result_pb.TaxCalculationResult.deserializeBinaryFromReader);
      msg.addTaxCalculationResults(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSubTotalPrice(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserRequest(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequirePaperReceipt(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonContactDelivery(value);
      break;
    case 40:
      var value = new type_ec_site_pb.EcSiteShop;
      reader.readMessage(value,type_ec_site_pb.EcSiteShop.deserializeBinaryFromReader);
      msg.setEcSiteShop(value);
      break;
    case 41:
      var value = new type_orderer_info_pb.OrdererInfo;
      reader.readMessage(value,type_orderer_info_pb.OrdererInfo.deserializeBinaryFromReader);
      msg.setOrdererInfo(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedeemedPoint(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestAlternativeItems(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointCardNumber(value);
      break;
    case 45:
      var value = new type_coupon_pb.AppliedCoupon;
      reader.readMessage(value,type_coupon_pb.AppliedCoupon.deserializeBinaryFromReader);
      msg.setAppliedCoupon(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCouponDiscount(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChargeAmount(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedDeliveryOnHoldReason(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryFee(value);
      break;
    case 50:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setDeliveryFeeType(value);
      break;
    case 51:
      var value = new proto.type.Order.Product;
      reader.readMessage(value,proto.type.Order.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 52:
      var value = new type_shipping_info_pb.ShippingInfo;
      reader.readMessage(value,type_shipping_info_pb.ShippingInfo.deserializeBinaryFromReader);
      msg.setShippingInfo(value);
      break;
    case 53:
      var value = /** @type {!proto.type.Order.DeliveryOnHoldReason} */ (reader.readEnum());
      msg.setDeliveryOnHoldReason(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAppliedFreeDeliverySubscription(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelNumber(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExclusiveDelivery(value);
      break;
    case 57:
      var value = new type_order_recipient_info_pb.OrderRecipientInfo;
      reader.readMessage(value,type_order_recipient_info_pb.OrderRecipientInfo.deserializeBinaryFromReader);
      msg.setOrderRecipientInfo(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEarnedRegularPoint(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEarnedBonusPoint(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeprecatedContainerCount(value);
      break;
    case 61:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContainsProductsOf20AgeRestriction(value);
      break;
    case 62:
      var value = new proto.type.Order.Container;
      reader.readMessage(value,proto.type.Order.Container.deserializeBinaryFromReader);
      msg.addContainers(value);
      break;
    case 63:
      var value = new type_ec_site_pb.PickupShopInformation;
      reader.readMessage(value,type_ec_site_pb.PickupShopInformation.deserializeBinaryFromReader);
      msg.setPickupShopInformation(value);
      break;
    case 64:
      var value = new type_ec_site_pb.PickupCarInformation;
      reader.readMessage(value,type_ec_site_pb.PickupCarInformation.deserializeBinaryFromReader);
      msg.setPickupCarInformation(value);
      break;
    case 65:
      var value = new type_ec_site_pb.PickupLockerInformation;
      reader.readMessage(value,type_ec_site_pb.PickupLockerInformation.deserializeBinaryFromReader);
      msg.setPickupLockerInformation(value);
      break;
    case 66:
      var value = /** @type {!proto.type.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentStatus(value);
      break;
    case 67:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPutOnHoldAt(value);
      break;
    case 68:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsChangedDeliveryTimeByStaff(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorporateOrderSummaryId(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCashOnDeliveryFee(value);
      break;
    case 71:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setCashOnDeliveryFeeType(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCardId(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureProofOfDeliveryUrl(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoOnDeliveryUrl(value);
      break;
    case 75:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPointCardLinked(value);
      break;
    case 76:
      var value = new type_order_bundle_pb.OrderBundle;
      reader.readMessage(value,type_order_bundle_pb.OrderBundle.deserializeBinaryFromReader);
      msg.setOrderBundle(value);
      break;
    case 77:
      var value = new type_order_total_price_pb.OrderTotalPrice;
      reader.readMessage(value,type_order_total_price_pb.OrderTotalPrice.deserializeBinaryFromReader);
      msg.setOrderTotalPrice(value);
      break;
    case 78:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeliveryTimeIsChangeable(value);
      break;
    case 79:
      var value = new type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer;
      reader.readMessage(value,type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer.deserializeBinaryFromReader);
      msg.setQualifiedInvoiceIssuer(value);
      break;
    case 80:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoiceFinalizedAt(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInvoiceFinalized(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelNumberPrefix(value);
      break;
    case 83:
      var value = new proto.type.Order.Fee;
      reader.readMessage(value,proto.type.Order.Fee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    case 84:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreDiscountTotalAmount(value);
      break;
    case 85:
      var value = new proto.type.Order.Discount;
      reader.readMessage(value,proto.type.Order.Discount.deserializeBinaryFromReader);
      msg.addDiscounts(value);
      break;
    case 86:
      var value = new proto.type.Order.FinalTotalPrice;
      reader.readMessage(value,proto.type.Order.FinalTotalPrice.deserializeBinaryFromReader);
      msg.setFinalTotalPrice(value);
      break;
    case 87:
      var value = new proto.type.Order.Payment;
      reader.readMessage(value,proto.type.Order.Payment.deserializeBinaryFromReader);
      msg.addPayments(value);
      break;
    case 88:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCompensated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeprecatedProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.type.Order.Product.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChangeDeadline();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrderedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsChangeable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsCancelable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInTaxTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getInTaxDeliveryFee();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDeprecatedStockOutProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.type.Order.StockOutProduct.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDeliveryTimeId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReceiveType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getCarBodyType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getCarColorType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getReceivedAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreparedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsApproaching();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsArrived();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getEarnedPoint();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getHeadingAt();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArrivedAt();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCanceledAt();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveredAt();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getInTaxSubTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getInTaxShoppingBagFee();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getEcSitePickupShop();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      type_ec_site_pb.EcSitePickupShop.serializeBinaryToWriter
    );
  }
  f = message.getNoTaxDeliveryFee();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getNoTaxSubTotalPrice();
  if (f !== 0) {
    writer.writeInt64(
      32,
      f
    );
  }
  f = message.getTaxCalculationResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      type_tax_calculation_result_pb.TaxCalculationResult.serializeBinaryToWriter
    );
  }
  f = message.getSubTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getUserRequest();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getRequirePaperReceipt();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getNonContactDelivery();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getEcSiteShop();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      type_ec_site_pb.EcSiteShop.serializeBinaryToWriter
    );
  }
  f = message.getOrdererInfo();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      type_orderer_info_pb.OrdererInfo.serializeBinaryToWriter
    );
  }
  f = message.getRedeemedPoint();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = message.getRequestAlternativeItems();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getPointCardNumber();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getAppliedCoupon();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      type_coupon_pb.AppliedCoupon.serializeBinaryToWriter
    );
  }
  f = message.getCouponDiscount();
  if (f !== 0) {
    writer.writeInt64(
      46,
      f
    );
  }
  f = message.getChargeAmount();
  if (f !== 0) {
    writer.writeInt64(
      47,
      f
    );
  }
  f = message.getDeprecatedDeliveryOnHoldReason();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getDeliveryFee();
  if (f !== 0) {
    writer.writeInt64(
      49,
      f
    );
  }
  f = message.getDeliveryFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      51,
      f,
      proto.type.Order.Product.serializeBinaryToWriter
    );
  }
  f = message.getShippingInfo();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      type_shipping_info_pb.ShippingInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryOnHoldReason();
  if (f !== 0.0) {
    writer.writeEnum(
      53,
      f
    );
  }
  f = message.getIsAppliedFreeDeliverySubscription();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getLabelNumber();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getIsExclusiveDelivery();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getOrderRecipientInfo();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      type_order_recipient_info_pb.OrderRecipientInfo.serializeBinaryToWriter
    );
  }
  f = message.getEarnedRegularPoint();
  if (f !== 0) {
    writer.writeInt64(
      58,
      f
    );
  }
  f = message.getEarnedBonusPoint();
  if (f !== 0) {
    writer.writeInt64(
      59,
      f
    );
  }
  f = message.getDeprecatedContainerCount();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
  f = message.getContainsProductsOf20AgeRestriction();
  if (f) {
    writer.writeBool(
      61,
      f
    );
  }
  f = message.getContainersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      62,
      f,
      proto.type.Order.Container.serializeBinaryToWriter
    );
  }
  f = message.getPickupShopInformation();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      type_ec_site_pb.PickupShopInformation.serializeBinaryToWriter
    );
  }
  f = message.getPickupCarInformation();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      type_ec_site_pb.PickupCarInformation.serializeBinaryToWriter
    );
  }
  f = message.getPickupLockerInformation();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      type_ec_site_pb.PickupLockerInformation.serializeBinaryToWriter
    );
  }
  f = message.getPaymentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      66,
      f
    );
  }
  f = message.getPutOnHoldAt();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsChangedDeliveryTimeByStaff();
  if (f) {
    writer.writeBool(
      68,
      f
    );
  }
  f = message.getCorporateOrderSummaryId();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getCashOnDeliveryFee();
  if (f !== 0) {
    writer.writeInt64(
      70,
      f
    );
  }
  f = message.getCashOnDeliveryFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      71,
      f
    );
  }
  f = message.getCreditCardId();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getPictureProofOfDeliveryUrl();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getPhotoOnDeliveryUrl();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getIsPointCardLinked();
  if (f) {
    writer.writeBool(
      75,
      f
    );
  }
  f = message.getOrderBundle();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      type_order_bundle_pb.OrderBundle.serializeBinaryToWriter
    );
  }
  f = message.getOrderTotalPrice();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      type_order_total_price_pb.OrderTotalPrice.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryTimeIsChangeable();
  if (f) {
    writer.writeBool(
      78,
      f
    );
  }
  f = message.getQualifiedInvoiceIssuer();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceFinalizedAt();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsInvoiceFinalized();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getLabelNumberPrefix();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      83,
      f,
      proto.type.Order.Fee.serializeBinaryToWriter
    );
  }
  f = message.getPreDiscountTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      84,
      f
    );
  }
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      85,
      f,
      proto.type.Order.Discount.serializeBinaryToWriter
    );
  }
  f = message.getFinalTotalPrice();
  if (f != null) {
    writer.writeMessage(
      86,
      f,
      proto.type.Order.FinalTotalPrice.serializeBinaryToWriter
    );
  }
  f = message.getPaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      87,
      f,
      proto.type.Order.Payment.serializeBinaryToWriter
    );
  }
  f = message.getIsCompensated();
  if (f) {
    writer.writeBool(
      88,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.Order.Status = {
  NONE: 0,
  PREPARING: 1,
  PREPARED: 2,
  DELIVERING: 3,
  RECEIVED: 4,
  CANCELED: 5,
  ARRIVED: 6,
  DELIVERY_ON_HOLD: 7,
  LOCKER_STORED: 8
};

/**
 * @enum {number}
 */
proto.type.Order.PaymentMethod = {
  CREDIT_CARD: 0,
  LOCAL_PAYMENT: 1,
  NONE_PAYMENT_METHOD: 2,
  CASH_ON_DELIVERY: 3,
  INVOICE: 4,
  BANK_ACCOUNT_TRANSFER: 5,
  COUPON: 6,
  POINT: 7
};

/**
 * @enum {number}
 */
proto.type.Order.DiscountType = {
  UNSPECIFIED_DISCOUNT: 0,
  COUPON_DISCOUNT: 1,
  POINT_DISCOUNT: 2
};

/**
 * @enum {number}
 */
proto.type.Order.FeeType = {
  UNSPECIFIED_FEE: 0,
  DELIVERY_FEE: 1,
  SERVICE_FEE: 2,
  CASH_ON_DELIVERY_FEE: 3,
  PACKAGE_DROP_FEE: 4
};

/**
 * @enum {number}
 */
proto.type.Order.CarBodyType = {
  SEDAN: 0,
  MINIVAN: 1,
  VAN: 2,
  SUV: 3,
  LIGHT_CAR: 4,
  OTHER_BODY: 5
};

/**
 * @enum {number}
 */
proto.type.Order.CarColorType = {
  WHITE: 0,
  BLACK: 1,
  SILVER: 2,
  RED: 3,
  BLUE: 4,
  GREEN: 5,
  OTHER_COLOR: 6,
  NONE_COLOR: 7
};

/**
 * @enum {number}
 */
proto.type.Order.DeliveryType = {
  DELIVERY_TYPE_UNSPECIFIED: 0,
  FROM_ONLINE: 1,
  FROM_PHYSICAL_STORE: 2
};

/**
 * @enum {number}
 */
proto.type.Order.DeliveryOnHoldReason = {
  UNKNOWN: 0,
  ABSENCE: 1,
  PENDING: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Payment.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Payment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Payment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Payment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Payment as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Payment.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.paymentMethod;

  /** @type {?|undefined} */
  this.amount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Payment.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Payment}
 */
proto.type.Order.Payment.fromObject = function(obj) {
  var msg = new proto.type.Order.Payment();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.paymentMethod != null && jspb.Message.setField(msg, 2, obj.paymentMethod);
  obj.amount != null && jspb.Message.setField(msg, 3, obj.amount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Payment}
 */
proto.type.Order.Payment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Payment;
  return proto.type.Order.Payment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Payment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Payment}
 */
proto.type.Order.Payment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.type.Order.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentMethod(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Payment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Payment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Payment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Payment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.type.Order.Payment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Payment} returns this
 */
proto.type.Order.Payment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentMethod payment_method = 2;
 * @return {!proto.type.Order.PaymentMethod}
 */
proto.type.Order.Payment.prototype.getPaymentMethod = function() {
  return /** @type {!proto.type.Order.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.Order.PaymentMethod} value
 * @return {!proto.type.Order.Payment} returns this
 */
proto.type.Order.Payment.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.type.Order.Payment.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Payment} returns this
 */
proto.type.Order.Payment.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    discountType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Discount as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Discount.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.discountType;

  /** @type {?|undefined} */
  this.amount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Discount.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Discount}
 */
proto.type.Order.Discount.fromObject = function(obj) {
  var msg = new proto.type.Order.Discount();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.discountType != null && jspb.Message.setField(msg, 2, obj.discountType);
  obj.amount != null && jspb.Message.setField(msg, 3, obj.amount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Discount}
 */
proto.type.Order.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Discount;
  return proto.type.Order.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Discount}
 */
proto.type.Order.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.type.Order.DiscountType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.type.Order.Discount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Discount} returns this
 */
proto.type.Order.Discount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DiscountType discount_type = 2;
 * @return {!proto.type.Order.DiscountType}
 */
proto.type.Order.Discount.prototype.getDiscountType = function() {
  return /** @type {!proto.type.Order.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.Order.DiscountType} value
 * @return {!proto.type.Order.Discount} returns this
 */
proto.type.Order.Discount.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.type.Order.Discount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Discount} returns this
 */
proto.type.Order.Discount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Fee.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Fee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Fee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Fee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: (f = msg.getPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    feeType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Fee as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Fee.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.feeType;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Fee.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Fee}
 */
proto.type.Order.Fee.fromObject = function(obj) {
  var msg = new proto.type.Order.Fee();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.price && jspb.Message.setWrapperField(
      msg, 2, type_price_pb.Price.fromObject(obj.price));
  obj.feeType != null && jspb.Message.setField(msg, 3, obj.feeType);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Fee}
 */
proto.type.Order.Fee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Fee;
  return proto.type.Order.Fee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Fee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Fee}
 */
proto.type.Order.Fee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {!proto.type.Order.FeeType} */ (reader.readEnum());
      msg.setFeeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Fee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Fee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Fee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Fee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.type.Order.Fee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Fee} returns this
 */
proto.type.Order.Fee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Price price = 2;
 * @return {?proto.type.Price}
 */
proto.type.Order.Fee.prototype.getPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 2));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Order.Fee} returns this
*/
proto.type.Order.Fee.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order.Fee} returns this
 */
proto.type.Order.Fee.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.Fee.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FeeType fee_type = 3;
 * @return {!proto.type.Order.FeeType}
 */
proto.type.Order.Fee.prototype.getFeeType = function() {
  return /** @type {!proto.type.Order.FeeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.type.Order.FeeType} value
 * @return {!proto.type.Order.Fee} returns this
 */
proto.type.Order.Fee.prototype.setFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.Order.FinalTotalPrice.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.FinalTotalPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.FinalTotalPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.FinalTotalPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.FinalTotalPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taxCalculationResultsList: jspb.Message.toObjectList(msg.getTaxCalculationResultsList(),
    type_tax_calculation_result_pb.TaxCalculationResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of FinalTotalPrice as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.FinalTotalPrice.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.amount;

  /** @type {?|undefined} */
  this.taxCalculationResultsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.FinalTotalPrice.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.FinalTotalPrice}
 */
proto.type.Order.FinalTotalPrice.fromObject = function(obj) {
  var msg = new proto.type.Order.FinalTotalPrice();
  obj.amount != null && jspb.Message.setField(msg, 1, obj.amount);
  obj.taxCalculationResultsList && jspb.Message.setRepeatedWrapperField(
      msg, 2, obj.taxCalculationResultsList.map(
          type_tax_calculation_result_pb.TaxCalculationResult.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.FinalTotalPrice}
 */
proto.type.Order.FinalTotalPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.FinalTotalPrice;
  return proto.type.Order.FinalTotalPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.FinalTotalPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.FinalTotalPrice}
 */
proto.type.Order.FinalTotalPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 2:
      var value = new type_tax_calculation_result_pb.TaxCalculationResult;
      reader.readMessage(value,type_tax_calculation_result_pb.TaxCalculationResult.deserializeBinaryFromReader);
      msg.addTaxCalculationResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.FinalTotalPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.FinalTotalPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.FinalTotalPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.FinalTotalPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaxCalculationResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      type_tax_calculation_result_pb.TaxCalculationResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 amount = 1;
 * @return {number}
 */
proto.type.Order.FinalTotalPrice.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.FinalTotalPrice} returns this
 */
proto.type.Order.FinalTotalPrice.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TaxCalculationResult tax_calculation_results = 2;
 * @return {!Array<!proto.type.TaxCalculationResult>}
 */
proto.type.Order.FinalTotalPrice.prototype.getTaxCalculationResultsList = function() {
  return /** @type{!Array<!proto.type.TaxCalculationResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_tax_calculation_result_pb.TaxCalculationResult, 2));
};


/**
 * @param {!Array<!proto.type.TaxCalculationResult>} value
 * @return {!proto.type.Order.FinalTotalPrice} returns this
*/
proto.type.Order.FinalTotalPrice.prototype.setTaxCalculationResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.type.TaxCalculationResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.TaxCalculationResult}
 */
proto.type.Order.FinalTotalPrice.prototype.addTaxCalculationResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.type.TaxCalculationResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order.FinalTotalPrice} returns this
 */
proto.type.Order.FinalTotalPrice.prototype.clearTaxCalculationResultsList = function() {
  return this.setTaxCalculationResultsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.Order.Product.oneofGroups_ = [[24,25]];

/**
 * @enum {number}
 */
proto.type.Order.Product.PricingMethodCase = {
  PRICING_METHOD_NOT_SET: 0,
  FIXED_PRICING: 24,
  WEIGHT_BASED_PRICING: 25
};

/**
 * @return {proto.type.Order.Product.PricingMethodCase}
 */
proto.type.Order.Product.prototype.getPricingMethodCase = function() {
  return /** @type {proto.type.Order.Product.PricingMethodCase} */(jspb.Message.computeOneofCase(this, proto.type.Order.Product.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    noTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    taxType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isSelfMedication: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isReducedTaxRate: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    price: jspb.Message.getFieldWithDefault(msg, 10, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    origin: jspb.Message.getFieldWithDefault(msg, 12, ""),
    requestedAmount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    hasStockOut: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isAlternative: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    deprecatedAlternativeOriginalProductName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    deprecatedAlternativeOriginalPrice: jspb.Message.getFieldWithDefault(msg, 17, 0),
    subscriptionType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 19, 0),
    alternativeOriginalProductId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    reservationGroupId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    changeDeadline: (f = msg.getChangeDeadline()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    displayWeight: jspb.Message.getFieldWithDefault(msg, 23, ""),
    fixedPricing: (f = msg.getFixedPricing()) && proto.type.Order.Product.PricingMethod.FixedPricing.toObject(includeInstance, f),
    weightBasedPricing: (f = msg.getWeightBasedPricing()) && proto.type.Order.Product.PricingMethod.WeightBasedPricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Product as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Product.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.amount;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.taxType;

  /** @type {?|undefined} */
  this.isSelfMedication;

  /** @type {?|undefined} */
  this.isReducedTaxRate;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.origin;

  /** @type {?|undefined} */
  this.requestedAmount;

  /** @type {?|undefined} */
  this.hasStockOut;

  /** @type {?|undefined} */
  this.isAlternative;

  /** @type {?|undefined} */
  this.deprecatedAlternativeOriginalProductName;

  /** @type {?|undefined} */
  this.deprecatedAlternativeOriginalPrice;

  /** @type {?|undefined} */
  this.subscriptionType;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.alternativeOriginalProductId;

  /** @type {?|undefined} */
  this.reservationGroupId;

  /** @type {?|undefined} */
  this.changeDeadline;

  /** @type {?|undefined} */
  this.displayWeight;

  /** @type {?|undefined} */
  this.fixedPricing;

  /** @type {?|undefined} */
  this.weightBasedPricing;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Product.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Product}
 */
proto.type.Order.Product.fromObject = function(obj) {
  var msg = new proto.type.Order.Product();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.amount != null && jspb.Message.setField(msg, 2, obj.amount);
  obj.inTaxPrice != null && jspb.Message.setField(msg, 3, obj.inTaxPrice);
  obj.imageUrl != null && jspb.Message.setField(msg, 4, obj.imageUrl);
  obj.productId != null && jspb.Message.setField(msg, 5, obj.productId);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 6, obj.noTaxPrice);
  obj.taxType != null && jspb.Message.setField(msg, 7, obj.taxType);
  obj.isSelfMedication != null && jspb.Message.setField(msg, 8, obj.isSelfMedication);
  obj.isReducedTaxRate != null && jspb.Message.setField(msg, 9, obj.isReducedTaxRate);
  obj.price != null && jspb.Message.setField(msg, 10, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 11, obj.priceType);
  obj.origin != null && jspb.Message.setField(msg, 12, obj.origin);
  obj.requestedAmount != null && jspb.Message.setField(msg, 13, obj.requestedAmount);
  obj.hasStockOut != null && jspb.Message.setField(msg, 14, obj.hasStockOut);
  obj.isAlternative != null && jspb.Message.setField(msg, 15, obj.isAlternative);
  obj.deprecatedAlternativeOriginalProductName != null && jspb.Message.setField(msg, 16, obj.deprecatedAlternativeOriginalProductName);
  obj.deprecatedAlternativeOriginalPrice != null && jspb.Message.setField(msg, 17, obj.deprecatedAlternativeOriginalPrice);
  obj.subscriptionType != null && jspb.Message.setField(msg, 18, obj.subscriptionType);
  obj.totalPrice != null && jspb.Message.setField(msg, 19, obj.totalPrice);
  obj.alternativeOriginalProductId != null && jspb.Message.setField(msg, 20, obj.alternativeOriginalProductId);
  obj.reservationGroupId != null && jspb.Message.setField(msg, 21, obj.reservationGroupId);
  obj.changeDeadline && jspb.Message.setWrapperField(
      msg, 22, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.changeDeadline));
  obj.displayWeight != null && jspb.Message.setField(msg, 23, obj.displayWeight);
  obj.fixedPricing && jspb.Message.setWrapperField(
      msg, 24, proto.type.Order.Product.PricingMethod.FixedPricing.fromObject(obj.fixedPricing));
  obj.weightBasedPricing && jspb.Message.setWrapperField(
      msg, 25, proto.type.Order.Product.PricingMethod.WeightBasedPricing.fromObject(obj.weightBasedPricing));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Product}
 */
proto.type.Order.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Product;
  return proto.type.Order.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Product}
 */
proto.type.Order.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNoTaxPrice(value);
      break;
    case 7:
      var value = /** @type {!proto.type.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelfMedication(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReducedTaxRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestedAmount(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStockOut(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAlternative(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedAlternativeOriginalProductName(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecatedAlternativeOriginalPrice(value);
      break;
    case 18:
      var value = /** @type {!proto.type.SubscriptionType} */ (reader.readEnum());
      msg.setSubscriptionType(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalPrice(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternativeOriginalProductId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationGroupId(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangeDeadline(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayWeight(value);
      break;
    case 24:
      var value = new proto.type.Order.Product.PricingMethod.FixedPricing;
      reader.readMessage(value,proto.type.Order.Product.PricingMethod.FixedPricing.deserializeBinaryFromReader);
      msg.setFixedPricing(value);
      break;
    case 25:
      var value = new proto.type.Order.Product.PricingMethod.WeightBasedPricing;
      reader.readMessage(value,proto.type.Order.Product.PricingMethod.WeightBasedPricing.deserializeBinaryFromReader);
      msg.setWeightBasedPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsSelfMedication();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsReducedTaxRate();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRequestedAmount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getHasStockOut();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsAlternative();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getDeprecatedAlternativeOriginalProductName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDeprecatedAlternativeOriginalPrice();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getSubscriptionType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getAlternativeOriginalProductId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getReservationGroupId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getChangeDeadline();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisplayWeight();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFixedPricing();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.type.Order.Product.PricingMethod.FixedPricing.serializeBinaryToWriter
    );
  }
  f = message.getWeightBasedPricing();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.type.Order.Product.PricingMethod.WeightBasedPricing.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Product.PricingMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Product.PricingMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Product.PricingMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PricingMethod as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Product.PricingMethod.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Product.PricingMethod.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Product.PricingMethod}
 */
proto.type.Order.Product.PricingMethod.fromObject = function(obj) {
  var msg = new proto.type.Order.Product.PricingMethod();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Product.PricingMethod}
 */
proto.type.Order.Product.PricingMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Product.PricingMethod;
  return proto.type.Order.Product.PricingMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Product.PricingMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Product.PricingMethod}
 */
proto.type.Order.Product.PricingMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Product.PricingMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Product.PricingMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Product.PricingMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Product.PricingMethod.FixedPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Product.PricingMethod.FixedPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.FixedPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unitPricePer100g: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of FixedPricing as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Product.PricingMethod.FixedPricing.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.unitPrice;

  /** @type {?|undefined} */
  this.unitPricePer100g;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Product.PricingMethod.FixedPricing.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Product.PricingMethod.FixedPricing}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.fromObject = function(obj) {
  var msg = new proto.type.Order.Product.PricingMethod.FixedPricing();
  obj.unitPrice != null && jspb.Message.setField(msg, 1, obj.unitPrice);
  obj.unitPricePer100g != null && jspb.Message.setField(msg, 2, obj.unitPricePer100g);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Product.PricingMethod.FixedPricing}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Product.PricingMethod.FixedPricing;
  return proto.type.Order.Product.PricingMethod.FixedPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Product.PricingMethod.FixedPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Product.PricingMethod.FixedPricing}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitPricePer100g(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Product.PricingMethod.FixedPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Product.PricingMethod.FixedPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.FixedPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUnitPricePer100g();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 unit_price = 1;
 * @return {number}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.getUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product.PricingMethod.FixedPricing} returns this
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.setUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 unit_price_per_100g = 2;
 * @return {number}
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.getUnitPricePer100g = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product.PricingMethod.FixedPricing} returns this
 */
proto.type.Order.Product.PricingMethod.FixedPricing.prototype.setUnitPricePer100g = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Product.PricingMethod.WeightBasedPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxUnitPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minUnitPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unitPricePer100g: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of WeightBasedPricing as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.maxUnitPrice;

  /** @type {?|undefined} */
  this.minUnitPrice;

  /** @type {?|undefined} */
  this.unitPricePer100g;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Product.PricingMethod.WeightBasedPricing.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.fromObject = function(obj) {
  var msg = new proto.type.Order.Product.PricingMethod.WeightBasedPricing();
  obj.maxUnitPrice != null && jspb.Message.setField(msg, 1, obj.maxUnitPrice);
  obj.minUnitPrice != null && jspb.Message.setField(msg, 2, obj.minUnitPrice);
  obj.unitPricePer100g != null && jspb.Message.setField(msg, 3, obj.unitPricePer100g);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Product.PricingMethod.WeightBasedPricing;
  return proto.type.Order.Product.PricingMethod.WeightBasedPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxUnitPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinUnitPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitPricePer100g(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Product.PricingMethod.WeightBasedPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUnitPricePer100g();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 max_unit_price = 1;
 * @return {number}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.getMaxUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} returns this
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.setMaxUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_unit_price = 2;
 * @return {number}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.getMinUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} returns this
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.setMinUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 unit_price_per_100g = 3;
 * @return {number}
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.getUnitPricePer100g = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product.PricingMethod.WeightBasedPricing} returns this
 */
proto.type.Order.Product.PricingMethod.WeightBasedPricing.prototype.setUnitPricePer100g = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.type.Order.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.type.Order.Product.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double in_tax_price = 3;
 * @return {number}
 */
proto.type.Order.Product.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.type.Order.Product.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.type.Order.Product.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double no_tax_price = 6;
 * @return {number}
 */
proto.type.Order.Product.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional TaxType tax_type = 7;
 * @return {!proto.type.TaxType}
 */
proto.type.Order.Product.prototype.getTaxType = function() {
  return /** @type {!proto.type.TaxType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.type.TaxType} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_self_medication = 8;
 * @return {boolean}
 */
proto.type.Order.Product.prototype.getIsSelfMedication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setIsSelfMedication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_reduced_tax_rate = 9;
 * @return {boolean}
 */
proto.type.Order.Product.prototype.getIsReducedTaxRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setIsReducedTaxRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 price = 10;
 * @return {number}
 */
proto.type.Order.Product.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional PriceType price_type = 11;
 * @return {!proto.type.PriceType}
 */
proto.type.Order.Product.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string origin = 12;
 * @return {string}
 */
proto.type.Order.Product.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 requested_amount = 13;
 * @return {number}
 */
proto.type.Order.Product.prototype.getRequestedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setRequestedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool has_stock_out = 14;
 * @return {boolean}
 */
proto.type.Order.Product.prototype.getHasStockOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setHasStockOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_alternative = 15;
 * @return {boolean}
 */
proto.type.Order.Product.prototype.getIsAlternative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setIsAlternative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string deprecated_alternative_original_product_name = 16;
 * @return {string}
 */
proto.type.Order.Product.prototype.getDeprecatedAlternativeOriginalProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setDeprecatedAlternativeOriginalProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 deprecated_alternative_original_price = 17;
 * @return {number}
 */
proto.type.Order.Product.prototype.getDeprecatedAlternativeOriginalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setDeprecatedAlternativeOriginalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional SubscriptionType subscription_type = 18;
 * @return {!proto.type.SubscriptionType}
 */
proto.type.Order.Product.prototype.getSubscriptionType = function() {
  return /** @type {!proto.type.SubscriptionType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.type.SubscriptionType} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setSubscriptionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int64 total_price = 19;
 * @return {number}
 */
proto.type.Order.Product.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string alternative_original_product_id = 20;
 * @return {string}
 */
proto.type.Order.Product.prototype.getAlternativeOriginalProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setAlternativeOriginalProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string reservation_group_id = 21;
 * @return {string}
 */
proto.type.Order.Product.prototype.getReservationGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setReservationGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp change_deadline = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.Product.prototype.getChangeDeadline = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order.Product} returns this
*/
proto.type.Order.Product.prototype.setChangeDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.clearChangeDeadline = function() {
  return this.setChangeDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.Product.prototype.hasChangeDeadline = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string display_weight = 23;
 * @return {string}
 */
proto.type.Order.Product.prototype.getDisplayWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.setDisplayWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional PricingMethod.FixedPricing fixed_pricing = 24;
 * @return {?proto.type.Order.Product.PricingMethod.FixedPricing}
 */
proto.type.Order.Product.prototype.getFixedPricing = function() {
  return /** @type{?proto.type.Order.Product.PricingMethod.FixedPricing} */ (
    jspb.Message.getWrapperField(this, proto.type.Order.Product.PricingMethod.FixedPricing, 24));
};


/**
 * @param {?proto.type.Order.Product.PricingMethod.FixedPricing|undefined} value
 * @return {!proto.type.Order.Product} returns this
*/
proto.type.Order.Product.prototype.setFixedPricing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.type.Order.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.clearFixedPricing = function() {
  return this.setFixedPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.Product.prototype.hasFixedPricing = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PricingMethod.WeightBasedPricing weight_based_pricing = 25;
 * @return {?proto.type.Order.Product.PricingMethod.WeightBasedPricing}
 */
proto.type.Order.Product.prototype.getWeightBasedPricing = function() {
  return /** @type{?proto.type.Order.Product.PricingMethod.WeightBasedPricing} */ (
    jspb.Message.getWrapperField(this, proto.type.Order.Product.PricingMethod.WeightBasedPricing, 25));
};


/**
 * @param {?proto.type.Order.Product.PricingMethod.WeightBasedPricing|undefined} value
 * @return {!proto.type.Order.Product} returns this
*/
proto.type.Order.Product.prototype.setWeightBasedPricing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.type.Order.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order.Product} returns this
 */
proto.type.Order.Product.prototype.clearWeightBasedPricing = function() {
  return this.setWeightBasedPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.Product.prototype.hasWeightBasedPricing = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.StockOutProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.StockOutProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.StockOutProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.StockOutProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    noTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    price: jspb.Message.getFieldWithDefault(msg, 7, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of StockOutProduct as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.StockOutProduct.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.amount;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.StockOutProduct.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.StockOutProduct}
 */
proto.type.Order.StockOutProduct.fromObject = function(obj) {
  var msg = new proto.type.Order.StockOutProduct();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.amount != null && jspb.Message.setField(msg, 2, obj.amount);
  obj.inTaxPrice != null && jspb.Message.setField(msg, 3, obj.inTaxPrice);
  obj.imageUrl != null && jspb.Message.setField(msg, 4, obj.imageUrl);
  obj.productId != null && jspb.Message.setField(msg, 5, obj.productId);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 6, obj.noTaxPrice);
  obj.price != null && jspb.Message.setField(msg, 7, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 8, obj.priceType);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.StockOutProduct}
 */
proto.type.Order.StockOutProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.StockOutProduct;
  return proto.type.Order.StockOutProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.StockOutProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.StockOutProduct}
 */
proto.type.Order.StockOutProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNoTaxPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.StockOutProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.StockOutProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.StockOutProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.StockOutProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.type.Order.StockOutProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.type.Order.StockOutProduct.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double in_tax_price = 3;
 * @return {number}
 */
proto.type.Order.StockOutProduct.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.type.Order.StockOutProduct.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.type.Order.StockOutProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double no_tax_price = 6;
 * @return {number}
 */
proto.type.Order.StockOutProduct.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 price = 7;
 * @return {number}
 */
proto.type.Order.StockOutProduct.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional PriceType price_type = 8;
 * @return {!proto.type.PriceType}
 */
proto.type.Order.StockOutProduct.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.Order.StockOutProduct} returns this
 */
proto.type.Order.StockOutProduct.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Order.Container.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Order.Container.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Order.Container} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Container.toObject = function(includeInstance, msg) {
  var f, obj = {
    containerType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Container as accepted by the `fromObject` method.
 * @record
 */
proto.type.Order.Container.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.containerType;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Order.Container.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Order.Container}
 */
proto.type.Order.Container.fromObject = function(obj) {
  var msg = new proto.type.Order.Container();
  obj.containerType != null && jspb.Message.setField(msg, 1, obj.containerType);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Order.Container}
 */
proto.type.Order.Container.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Order.Container;
  return proto.type.Order.Container.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Order.Container} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Order.Container}
 */
proto.type.Order.Container.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.ContainerType} */ (reader.readEnum());
      msg.setContainerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Order.Container.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Order.Container.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Order.Container} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Order.Container.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContainerType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ContainerType container_type = 1;
 * @return {!proto.type.ContainerType}
 */
proto.type.Order.Container.prototype.getContainerType = function() {
  return /** @type {!proto.type.ContainerType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.ContainerType} value
 * @return {!proto.type.Order.Container} returns this
 */
proto.type.Order.Container.prototype.setContainerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.type.Order.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Product deprecated_products = 2;
 * @return {!Array<!proto.type.Order.Product>}
 */
proto.type.Order.prototype.getDeprecatedProductsList = function() {
  return /** @type{!Array<!proto.type.Order.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Product, 2));
};


/**
 * @param {!Array<!proto.type.Order.Product>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDeprecatedProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.type.Order.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Product}
 */
proto.type.Order.prototype.addDeprecatedProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.type.Order.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDeprecatedProductsList = function() {
  return this.setDeprecatedProductsList([]);
};


/**
 * optional google.protobuf.Timestamp delivery_from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getDeliveryFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDeliveryFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDeliveryFrom = function() {
  return this.setDeliveryFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasDeliveryFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getDeliveryTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDeliveryTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDeliveryTo = function() {
  return this.setDeliveryTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasDeliveryTo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp change_deadline = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getChangeDeadline = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setChangeDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearChangeDeadline = function() {
  return this.setChangeDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasChangeDeadline = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp ordered_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getOrderedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setOrderedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearOrderedAt = function() {
  return this.setOrderedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasOrderedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_changeable = 7;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsChangeable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsChangeable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_cancelable = 8;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsCancelable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsCancelable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional double in_tax_total_price = 9;
 * @return {number}
 */
proto.type.Order.prototype.getInTaxTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setInTaxTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double in_tax_delivery_fee = 10;
 * @return {number}
 */
proto.type.Order.prototype.getInTaxDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setInTaxDeliveryFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * repeated StockOutProduct deprecated_stock_out_products = 11;
 * @return {!Array<!proto.type.Order.StockOutProduct>}
 */
proto.type.Order.prototype.getDeprecatedStockOutProductsList = function() {
  return /** @type{!Array<!proto.type.Order.StockOutProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.StockOutProduct, 11));
};


/**
 * @param {!Array<!proto.type.Order.StockOutProduct>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDeprecatedStockOutProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.type.Order.StockOutProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.StockOutProduct}
 */
proto.type.Order.prototype.addDeprecatedStockOutProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.type.Order.StockOutProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDeprecatedStockOutProductsList = function() {
  return this.setDeprecatedStockOutProductsList([]);
};


/**
 * optional Status status = 12;
 * @return {!proto.type.Order.Status}
 */
proto.type.Order.prototype.getStatus = function() {
  return /** @type {!proto.type.Order.Status} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.type.Order.Status} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string delivery_time_id = 13;
 * @return {string}
 */
proto.type.Order.prototype.getDeliveryTimeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeliveryTimeId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string shop_name = 14;
 * @return {string}
 */
proto.type.Order.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional OrderReceiveKind receive_type = 15;
 * @return {!proto.type.OrderReceiveKind}
 */
proto.type.Order.prototype.getReceiveType = function() {
  return /** @type {!proto.type.OrderReceiveKind} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.type.OrderReceiveKind} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setReceiveType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional CarBodyType car_body_type = 16;
 * @return {!proto.type.Order.CarBodyType}
 */
proto.type.Order.prototype.getCarBodyType = function() {
  return /** @type {!proto.type.Order.CarBodyType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.type.Order.CarBodyType} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCarBodyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional CarColorType car_color_type = 17;
 * @return {!proto.type.Order.CarColorType}
 */
proto.type.Order.prototype.getCarColorType = function() {
  return /** @type {!proto.type.Order.CarColorType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.type.Order.CarColorType} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCarColorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional google.protobuf.Timestamp received_at = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getReceivedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setReceivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearReceivedAt = function() {
  return this.setReceivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasReceivedAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp prepared_at = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getPreparedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPreparedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPreparedAt = function() {
  return this.setPreparedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasPreparedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool is_approaching = 20;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsApproaching = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsApproaching = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool is_arrived = 21;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsArrived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsArrived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int64 earned_point = 22;
 * @return {number}
 */
proto.type.Order.prototype.getEarnedPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setEarnedPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp heading_at = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getHeadingAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setHeadingAt = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearHeadingAt = function() {
  return this.setHeadingAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasHeadingAt = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp arrived_at = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getArrivedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setArrivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearArrivedAt = function() {
  return this.setArrivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasArrivedAt = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Timestamp canceled_at = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getCanceledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setCanceledAt = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearCanceledAt = function() {
  return this.setCanceledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasCanceledAt = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.Timestamp delivered_at = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getDeliveredAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDeliveredAt = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDeliveredAt = function() {
  return this.setDeliveredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasDeliveredAt = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional PaymentMethod payment_method = 27;
 * @return {!proto.type.Order.PaymentMethod}
 */
proto.type.Order.prototype.getPaymentMethod = function() {
  return /** @type {!proto.type.Order.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.type.Order.PaymentMethod} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional double in_tax_sub_total_price = 28;
 * @return {number}
 */
proto.type.Order.prototype.getInTaxSubTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setInTaxSubTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double in_tax_shopping_bag_fee = 29;
 * @return {number}
 */
proto.type.Order.prototype.getInTaxShoppingBagFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setInTaxShoppingBagFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional EcSitePickupShop ec_site_pickup_shop = 30;
 * @return {?proto.type.EcSitePickupShop}
 */
proto.type.Order.prototype.getEcSitePickupShop = function() {
  return /** @type{?proto.type.EcSitePickupShop} */ (
    jspb.Message.getWrapperField(this, type_ec_site_pb.EcSitePickupShop, 30));
};


/**
 * @param {?proto.type.EcSitePickupShop|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setEcSitePickupShop = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearEcSitePickupShop = function() {
  return this.setEcSitePickupShop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasEcSitePickupShop = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional int64 no_tax_delivery_fee = 31;
 * @return {number}
 */
proto.type.Order.prototype.getNoTaxDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setNoTaxDeliveryFee = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int64 no_tax_sub_total_price = 32;
 * @return {number}
 */
proto.type.Order.prototype.getNoTaxSubTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setNoTaxSubTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * repeated TaxCalculationResult tax_calculation_results = 33;
 * @return {!Array<!proto.type.TaxCalculationResult>}
 */
proto.type.Order.prototype.getTaxCalculationResultsList = function() {
  return /** @type{!Array<!proto.type.TaxCalculationResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_tax_calculation_result_pb.TaxCalculationResult, 33));
};


/**
 * @param {!Array<!proto.type.TaxCalculationResult>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setTaxCalculationResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.type.TaxCalculationResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.TaxCalculationResult}
 */
proto.type.Order.prototype.addTaxCalculationResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.type.TaxCalculationResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearTaxCalculationResultsList = function() {
  return this.setTaxCalculationResultsList([]);
};


/**
 * optional double sub_total_price = 34;
 * @return {number}
 */
proto.type.Order.prototype.getSubTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setSubTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double total_price = 35;
 * @return {number}
 */
proto.type.Order.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional string user_id = 36;
 * @return {string}
 */
proto.type.Order.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string user_request = 37;
 * @return {string}
 */
proto.type.Order.prototype.getUserRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setUserRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional bool require_paper_receipt = 38;
 * @return {boolean}
 */
proto.type.Order.prototype.getRequirePaperReceipt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setRequirePaperReceipt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool non_contact_delivery = 39;
 * @return {boolean}
 */
proto.type.Order.prototype.getNonContactDelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setNonContactDelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional EcSiteShop ec_site_shop = 40;
 * @return {?proto.type.EcSiteShop}
 */
proto.type.Order.prototype.getEcSiteShop = function() {
  return /** @type{?proto.type.EcSiteShop} */ (
    jspb.Message.getWrapperField(this, type_ec_site_pb.EcSiteShop, 40));
};


/**
 * @param {?proto.type.EcSiteShop|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setEcSiteShop = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearEcSiteShop = function() {
  return this.setEcSiteShop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasEcSiteShop = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional OrdererInfo orderer_info = 41;
 * @return {?proto.type.OrdererInfo}
 */
proto.type.Order.prototype.getOrdererInfo = function() {
  return /** @type{?proto.type.OrdererInfo} */ (
    jspb.Message.getWrapperField(this, type_orderer_info_pb.OrdererInfo, 41));
};


/**
 * @param {?proto.type.OrdererInfo|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setOrdererInfo = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearOrdererInfo = function() {
  return this.setOrdererInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasOrdererInfo = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional int64 redeemed_point = 42;
 * @return {number}
 */
proto.type.Order.prototype.getRedeemedPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setRedeemedPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional bool request_alternative_items = 43;
 * @return {boolean}
 */
proto.type.Order.prototype.getRequestAlternativeItems = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setRequestAlternativeItems = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional string point_card_number = 44;
 * @return {string}
 */
proto.type.Order.prototype.getPointCardNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPointCardNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional AppliedCoupon applied_coupon = 45;
 * @return {?proto.type.AppliedCoupon}
 */
proto.type.Order.prototype.getAppliedCoupon = function() {
  return /** @type{?proto.type.AppliedCoupon} */ (
    jspb.Message.getWrapperField(this, type_coupon_pb.AppliedCoupon, 45));
};


/**
 * @param {?proto.type.AppliedCoupon|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setAppliedCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearAppliedCoupon = function() {
  return this.setAppliedCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasAppliedCoupon = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional int64 coupon_discount = 46;
 * @return {number}
 */
proto.type.Order.prototype.getCouponDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCouponDiscount = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional int64 charge_amount = 47;
 * @return {number}
 */
proto.type.Order.prototype.getChargeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setChargeAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional string deprecated_delivery_on_hold_reason = 48;
 * @return {string}
 */
proto.type.Order.prototype.getDeprecatedDeliveryOnHoldReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeprecatedDeliveryOnHoldReason = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional int64 delivery_fee = 49;
 * @return {number}
 */
proto.type.Order.prototype.getDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeliveryFee = function(value) {
  return jspb.Message.setProto3IntField(this, 49, value);
};


/**
 * optional PriceType delivery_fee_type = 50;
 * @return {!proto.type.PriceType}
 */
proto.type.Order.prototype.getDeliveryFeeType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeliveryFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * repeated Product products = 51;
 * @return {!Array<!proto.type.Order.Product>}
 */
proto.type.Order.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.type.Order.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Product, 51));
};


/**
 * @param {!Array<!proto.type.Order.Product>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 51, value);
};


/**
 * @param {!proto.type.Order.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Product}
 */
proto.type.Order.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 51, opt_value, proto.type.Order.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional ShippingInfo shipping_info = 52;
 * @return {?proto.type.ShippingInfo}
 */
proto.type.Order.prototype.getShippingInfo = function() {
  return /** @type{?proto.type.ShippingInfo} */ (
    jspb.Message.getWrapperField(this, type_shipping_info_pb.ShippingInfo, 52));
};


/**
 * @param {?proto.type.ShippingInfo|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setShippingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearShippingInfo = function() {
  return this.setShippingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasShippingInfo = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional DeliveryOnHoldReason delivery_on_hold_reason = 53;
 * @return {!proto.type.Order.DeliveryOnHoldReason}
 */
proto.type.Order.prototype.getDeliveryOnHoldReason = function() {
  return /** @type {!proto.type.Order.DeliveryOnHoldReason} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {!proto.type.Order.DeliveryOnHoldReason} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeliveryOnHoldReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 53, value);
};


/**
 * optional bool is_applied_free_delivery_subscription = 54;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsAppliedFreeDeliverySubscription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsAppliedFreeDeliverySubscription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional string label_number = 55;
 * @return {string}
 */
proto.type.Order.prototype.getLabelNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setLabelNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional bool is_exclusive_delivery = 56;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsExclusiveDelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsExclusiveDelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional OrderRecipientInfo order_recipient_info = 57;
 * @return {?proto.type.OrderRecipientInfo}
 */
proto.type.Order.prototype.getOrderRecipientInfo = function() {
  return /** @type{?proto.type.OrderRecipientInfo} */ (
    jspb.Message.getWrapperField(this, type_order_recipient_info_pb.OrderRecipientInfo, 57));
};


/**
 * @param {?proto.type.OrderRecipientInfo|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setOrderRecipientInfo = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearOrderRecipientInfo = function() {
  return this.setOrderRecipientInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasOrderRecipientInfo = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional int64 earned_regular_point = 58;
 * @return {number}
 */
proto.type.Order.prototype.getEarnedRegularPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setEarnedRegularPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional int64 earned_bonus_point = 59;
 * @return {number}
 */
proto.type.Order.prototype.getEarnedBonusPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setEarnedBonusPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional int32 deprecated_container_count = 60;
 * @return {number}
 */
proto.type.Order.prototype.getDeprecatedContainerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeprecatedContainerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional bool contains_products_of_20_age_restriction = 61;
 * @return {boolean}
 */
proto.type.Order.prototype.getContainsProductsOf20AgeRestriction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 61, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setContainsProductsOf20AgeRestriction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 61, value);
};


/**
 * repeated Container containers = 62;
 * @return {!Array<!proto.type.Order.Container>}
 */
proto.type.Order.prototype.getContainersList = function() {
  return /** @type{!Array<!proto.type.Order.Container>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Container, 62));
};


/**
 * @param {!Array<!proto.type.Order.Container>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setContainersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 62, value);
};


/**
 * @param {!proto.type.Order.Container=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Container}
 */
proto.type.Order.prototype.addContainers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 62, opt_value, proto.type.Order.Container, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearContainersList = function() {
  return this.setContainersList([]);
};


/**
 * optional PickupShopInformation pickup_shop_information = 63;
 * @return {?proto.type.PickupShopInformation}
 */
proto.type.Order.prototype.getPickupShopInformation = function() {
  return /** @type{?proto.type.PickupShopInformation} */ (
    jspb.Message.getWrapperField(this, type_ec_site_pb.PickupShopInformation, 63));
};


/**
 * @param {?proto.type.PickupShopInformation|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPickupShopInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.type.Order.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPickupShopInformation = function() {
  return this.setPickupShopInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasPickupShopInformation = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional PickupCarInformation pickup_car_information = 64;
 * @return {?proto.type.PickupCarInformation}
 */
proto.type.Order.prototype.getPickupCarInformation = function() {
  return /** @type{?proto.type.PickupCarInformation} */ (
    jspb.Message.getWrapperField(this, type_ec_site_pb.PickupCarInformation, 64));
};


/**
 * @param {?proto.type.PickupCarInformation|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPickupCarInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 64, proto.type.Order.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPickupCarInformation = function() {
  return this.setPickupCarInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasPickupCarInformation = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional PickupLockerInformation pickup_locker_information = 65;
 * @return {?proto.type.PickupLockerInformation}
 */
proto.type.Order.prototype.getPickupLockerInformation = function() {
  return /** @type{?proto.type.PickupLockerInformation} */ (
    jspb.Message.getWrapperField(this, type_ec_site_pb.PickupLockerInformation, 65));
};


/**
 * @param {?proto.type.PickupLockerInformation|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPickupLockerInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 65, proto.type.Order.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPickupLockerInformation = function() {
  return this.setPickupLockerInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasPickupLockerInformation = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional PaymentStatus payment_status = 66;
 * @return {!proto.type.PaymentStatus}
 */
proto.type.Order.prototype.getPaymentStatus = function() {
  return /** @type {!proto.type.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {!proto.type.PaymentStatus} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 66, value);
};


/**
 * optional google.protobuf.Timestamp put_on_hold_at = 67;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getPutOnHoldAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 67));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPutOnHoldAt = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPutOnHoldAt = function() {
  return this.setPutOnHoldAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasPutOnHoldAt = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional bool is_changed_delivery_time_by_staff = 68;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsChangedDeliveryTimeByStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 68, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsChangedDeliveryTimeByStaff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 68, value);
};


/**
 * optional string corporate_order_summary_id = 69;
 * @return {string}
 */
proto.type.Order.prototype.getCorporateOrderSummaryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCorporateOrderSummaryId = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional int64 cash_on_delivery_fee = 70;
 * @return {number}
 */
proto.type.Order.prototype.getCashOnDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCashOnDeliveryFee = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};


/**
 * optional PriceType cash_on_delivery_fee_type = 71;
 * @return {!proto.type.PriceType}
 */
proto.type.Order.prototype.getCashOnDeliveryFeeType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCashOnDeliveryFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 71, value);
};


/**
 * optional string credit_card_id = 72;
 * @return {string}
 */
proto.type.Order.prototype.getCreditCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setCreditCardId = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string picture_proof_of_delivery_url = 73;
 * @return {string}
 */
proto.type.Order.prototype.getPictureProofOfDeliveryUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPictureProofOfDeliveryUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional string photo_on_delivery_url = 74;
 * @return {string}
 */
proto.type.Order.prototype.getPhotoOnDeliveryUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPhotoOnDeliveryUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional bool is_point_card_linked = 75;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsPointCardLinked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 75, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsPointCardLinked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 75, value);
};


/**
 * optional OrderBundle order_bundle = 76;
 * @return {?proto.type.OrderBundle}
 */
proto.type.Order.prototype.getOrderBundle = function() {
  return /** @type{?proto.type.OrderBundle} */ (
    jspb.Message.getWrapperField(this, type_order_bundle_pb.OrderBundle, 76));
};


/**
 * @param {?proto.type.OrderBundle|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setOrderBundle = function(value) {
  return jspb.Message.setWrapperField(this, 76, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearOrderBundle = function() {
  return this.setOrderBundle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasOrderBundle = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional OrderTotalPrice order_total_price = 77;
 * @return {?proto.type.OrderTotalPrice}
 */
proto.type.Order.prototype.getOrderTotalPrice = function() {
  return /** @type{?proto.type.OrderTotalPrice} */ (
    jspb.Message.getWrapperField(this, type_order_total_price_pb.OrderTotalPrice, 77));
};


/**
 * @param {?proto.type.OrderTotalPrice|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setOrderTotalPrice = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearOrderTotalPrice = function() {
  return this.setOrderTotalPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasOrderTotalPrice = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional bool delivery_time_is_changeable = 78;
 * @return {boolean}
 */
proto.type.Order.prototype.getDeliveryTimeIsChangeable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 78, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setDeliveryTimeIsChangeable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 78, value);
};


/**
 * optional QualifiedInvoiceIssuer qualified_invoice_issuer = 79;
 * @return {?proto.type.QualifiedInvoiceIssuer}
 */
proto.type.Order.prototype.getQualifiedInvoiceIssuer = function() {
  return /** @type{?proto.type.QualifiedInvoiceIssuer} */ (
    jspb.Message.getWrapperField(this, type_qualified_invoice_issuer_pb.QualifiedInvoiceIssuer, 79));
};


/**
 * @param {?proto.type.QualifiedInvoiceIssuer|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setQualifiedInvoiceIssuer = function(value) {
  return jspb.Message.setWrapperField(this, 79, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearQualifiedInvoiceIssuer = function() {
  return this.setQualifiedInvoiceIssuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasQualifiedInvoiceIssuer = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional google.protobuf.Timestamp invoice_finalized_at = 80;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Order.prototype.getInvoiceFinalizedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 80));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setInvoiceFinalizedAt = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearInvoiceFinalizedAt = function() {
  return this.setInvoiceFinalizedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasInvoiceFinalizedAt = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional bool is_invoice_finalized = 81;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsInvoiceFinalized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsInvoiceFinalized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional string label_number_prefix = 82;
 * @return {string}
 */
proto.type.Order.prototype.getLabelNumberPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setLabelNumberPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * repeated Fee fees = 83;
 * @return {!Array<!proto.type.Order.Fee>}
 */
proto.type.Order.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.type.Order.Fee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Fee, 83));
};


/**
 * @param {!Array<!proto.type.Order.Fee>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 83, value);
};


/**
 * @param {!proto.type.Order.Fee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Fee}
 */
proto.type.Order.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 83, opt_value, proto.type.Order.Fee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};


/**
 * optional int64 pre_discount_total_amount = 84;
 * @return {number}
 */
proto.type.Order.prototype.getPreDiscountTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 84, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setPreDiscountTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 84, value);
};


/**
 * repeated Discount discounts = 85;
 * @return {!Array<!proto.type.Order.Discount>}
 */
proto.type.Order.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.type.Order.Discount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Discount, 85));
};


/**
 * @param {!Array<!proto.type.Order.Discount>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 85, value);
};


/**
 * @param {!proto.type.Order.Discount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Discount}
 */
proto.type.Order.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 85, opt_value, proto.type.Order.Discount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};


/**
 * optional FinalTotalPrice final_total_price = 86;
 * @return {?proto.type.Order.FinalTotalPrice}
 */
proto.type.Order.prototype.getFinalTotalPrice = function() {
  return /** @type{?proto.type.Order.FinalTotalPrice} */ (
    jspb.Message.getWrapperField(this, proto.type.Order.FinalTotalPrice, 86));
};


/**
 * @param {?proto.type.Order.FinalTotalPrice|undefined} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setFinalTotalPrice = function(value) {
  return jspb.Message.setWrapperField(this, 86, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearFinalTotalPrice = function() {
  return this.setFinalTotalPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Order.prototype.hasFinalTotalPrice = function() {
  return jspb.Message.getField(this, 86) != null;
};


/**
 * repeated Payment payments = 87;
 * @return {!Array<!proto.type.Order.Payment>}
 */
proto.type.Order.prototype.getPaymentsList = function() {
  return /** @type{!Array<!proto.type.Order.Payment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.Order.Payment, 87));
};


/**
 * @param {!Array<!proto.type.Order.Payment>} value
 * @return {!proto.type.Order} returns this
*/
proto.type.Order.prototype.setPaymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 87, value);
};


/**
 * @param {!proto.type.Order.Payment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.Order.Payment}
 */
proto.type.Order.prototype.addPayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 87, opt_value, proto.type.Order.Payment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.clearPaymentsList = function() {
  return this.setPaymentsList([]);
};


/**
 * optional bool is_compensated = 88;
 * @return {boolean}
 */
proto.type.Order.prototype.getIsCompensated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 88, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.Order} returns this
 */
proto.type.Order.prototype.setIsCompensated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 88, value);
};


/**
 * @enum {number}
 */
proto.type.PaymentStatus = {
  PAYMENT_STATUS_NONE: 0,
  PAYMENT_STATUS_AUTHORIZED: 1,
  PAYMENT_STATUS_CAPTURED: 2,
  PAYMENT_STATUS_CANCELED: 3
};

goog.object.extend(exports, proto.type);
